<template>
    <div class="grid grid-cols-4 gap-6">
        <div class="col-span-1">
            <ul class="space-y-1 select-none pb-3 mb-3 border-b border-gray-200">
                <li
                    v-for="macro in $state.macros"
                    :key="macro.id"
                    @click="selectMacro(macro)"
                    class="flex items-center px-2 py-1 capitalize rounded cursor-pointer"
                    :class="{
                        'bg-gray-100': macro.id === $state.selectedMacro?.id
                    }"
                >
                    <glow :green="macro.id === $state.selectedMacro?.id" />
                    <span class="truncate">{{ macro.name }}</span>
                </li>
            </ul>
            <btn green small block link @click="createNewMacro">Create New Macro</btn>
        </div>
        <div class="col-span-3">
            <div v-if="$state.selectedMacro">
                <div class="flex flex-col gap-2 w-full">
                    <div v-if="$state.selectedMacro.body.subject">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                            {{ $state.selectedMacro.body.subject }}
                        </h3>
                    </div>
                    <div
                        class="w-full bg-gray-50 py-2 px-3 rounded border border-gray-200"
                        v-html="$state.selectedMacro.body.content"
                    />
                    <div v-if="$state.selectedMacro.body.attachments.length">
                        <ul class="flex gap-2 text-xs items-center flex-wrap">
                            <li><label for="attachments" class="font-medium leading-5 text-gray-700">Attachments:</label></li>
                            <li
                                v-for="(attachment, idx) in $state.selectedMacro.body.attachments"
                                :key="'attachment-' + attachment.id"
                            >
                                <span class="flex items-center gap-1">
                                    <a :href="attachment.fullUrl" target="_blank" class="text-blue-400 underline">{{ attachment.fileName }}</a>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="flex items-center justify-between">
                        <btn
                            v-if="$state.selectedMacro.is_shared || $state.selectedMacro.created_by === $state.user.id"
                            gray
                            outline
                            small
                            @click="editSelectedMacro"
                        >
                            Edit The Macro
                        </btn>
                        <btn green small @click="useMacro">Use This Macro</btn>
                    </div>
                </div>
            </div>
            <div v-else-if="$state.showForm" class="flex flex-col gap-y-4">
                <inp v-model="$macroForm.name" placeholder="The Macro's Name"/>
                <hr>
                <inp v-model="$macroForm.body.subject" placeholder="Email Subject (optional)"/>
                <editor
                    v-model="$macroForm.body.content"
                    placeholder="Email Body"
                />
                <div v-if="$macroForm.body.attachments.length">
                    <ul class="flex gap-2 text-xs items-center flex-wrap">
                        <li><label for="attachments" class="font-medium leading-5 text-gray-700">Attachments:</label></li>
                        <li
                            v-for="(attachment, idx) in $macroForm.body.attachments"
                            :key="'attachment-' + attachment.id"
                        >
                            <span class="flex items-center gap-1">
                                <a :href="attachment.fullUrl" target="_blank" class="text-blue-400 underline">{{ attachment.fileName }}</a>
                                <a
                                    href="#"
                                    class="text-gray-600 hover:text-red-400"
                                    @click.prevent="$macroForm.body.attachments.splice(idx, 1)"
                                ><i class="fa fa-times"></i></a>
                            </span>
                        </li>
                    </ul>
                </div>
                <input
                    ref="fileInput"
                    type="file"
                    class="hidden"
                    @change="uploadFiles"
                    multiple
                />
                <btn gray @click="fileInput.click()">
                    Add Attachments
                </btn>
                <div class="flex gap-1">
                    <toggle v-model="$macroForm.is_shared" name="is_shared" />
                    <label
                        for="is_shared"
                        class="block mt-px text-sm font-medium leading-5 text-gray-700 cursor-pointer"
                        @click="$macroForm.is_shared = !$macroForm.is_shared"
                    >
                        Share this macro with all team members?
                    </label>
                </div>
                <div class="flex justify-between gap-2">
                    <div class="flex items-center gap-2">
                        <btn gray outline small @click="cancelMacroEdit">Cancel</btn>
                        <btn
                            red small
                            @click="deleteMacro($macroForm)"
                            v-if="$macroForm.id"
                        >
                            Delete The Macro
                        </btn>
                    </div>
                    <btn green small @click="saveMacro">Save The Macro</btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import btn from "@/Common/btn.vue"
import inp from "@/Common/inp.vue"
import editor from "@/Common/editor.vue"
import toggle from "@/Common/toggle.vue"
import glow from "@/Common/glow.vue"
import axios from "axios"
import { usePage } from '@inertiajs/vue3'
import { reactive, inject, onBeforeMount, ref } from "vue"

const $route = inject('$route')

const $emit = defineEmits(['use'])

const fileInput = ref(null)

const uploadFiles = (files = null) => {
    const promises = (Array.isArray(files) ? files : Array.from(fileInput.value.files)).map((file) => {
        // upload file from $refs.file
        const formData = new FormData();
        formData.append('file', file);
        // update to media.update using axios
        return axios.post($route('media.upload'), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    });

    Promise.all(promises).then((responses) => {
        // clear the file input
        fileInput.value.value = null;
        responses.forEach(({ data }) => {
            $macroForm.body.attachments.push(data);
        })
    })
}

const $state = reactive({
    user: usePage().props.auth.user,
    showForm: false,
    macros: [],
    selectedMacro: null
})

onBeforeMount(() => {
    axios.get($route('macros.index')).then(res => {
        $state.macros = res.data
    })
})

const $macroForm = reactive({
    id: null,
    name: '',
    is_shared: false,
    body: {
        content: '',
        attachments: []
    }
})

const useMacro = () => {
    $emit('use', $state.selectedMacro)
}

const selectMacro = (macro) => {
    $state.selectedMacro = macro
}

const cancelMacroEdit = () => {
    $state.showForm = false
    if ($macroForm.id) {
        $state.selectedMacro = $state.macros.find(macro => macro.id === $macroForm.id)
    } else {
        $state.selectedMacro = null
    }
}

const createNewMacro = () => {
    $state.selectedMacro = null
    $macroForm.id = null
    $macroForm.name = ''
    $macroForm.is_shared = false
    $macroForm.body = {
        content: '',
        attachments: []
    }
    $state.showForm = true

}

const editSelectedMacro = () => {
    $macroForm.id = $state.selectedMacro.id
    $macroForm.name = $state.selectedMacro.name
    $macroForm.is_shared = $state.selectedMacro.is_shared
    $macroForm.body = $state.selectedMacro.body
    $state.showForm = true
    $state.selectedMacro = null
}

const deleteMacro = (macro) => {
    if (!confirm(`Are you sure you want to delete the "${macro.name}" macro?`)) {
        return
    }

    axios.delete($route('macros.destroy', macro.id)).then(res => {
        $state.macros = $state.macros.filter(m => m.id !== macro.id)
        $state.selectedMacro = null
        $state.showForm = false
    })
}

const saveMacro = () => {
    const url = $macroForm.id ? $route('macros.update', $macroForm.id) : $route('macros.store')
    const method = $macroForm.id ? 'put' : 'post'

    axios[method](url, $macroForm).then(res => {
        if ($macroForm.id) {
            $state.macros = $state.macros.map(macro => macro.id === res.data.id ? res.data : macro)
        } else {
            $state.macros.push(res.data)
        }
        $state.selectedMacro = res.data
        $state.showForm = false
    })
}
</script>
