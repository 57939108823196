<template>
    <Layout :auth='auth'>
        <Head :title='(memo.subject && memo.subject.length > 20) ? (memo.subject.substr(0, 15) + "...") : memo.subject' />
        <template v-slot:title>
            Memo
        </template>
        <template v-slot:actions>
            <btn white @click="back()">Back To Activity</btn>
        </template>
        <template v-if="memo">
            <MemoContent
                v-bind="{
                    auth: auth,
                    memo: memo,
                    relatedMemos: relatedMemos,
                    activities: activities,
                    clientsById: clientsById,
                    usersById: usersById,
                    teamsById: teamsById,
                    watching: watching,
                    actions: actions,
                    checklists: checklists
                }"
            />
        </template>
    </Layout>
</template>

<script>
import btnGroup from "@/Common/btnGroup.vue"
import btn from "@/Common/btn.vue"
import avatar from "@/Common/avatar.vue"
import dropZone from "@/Common/dropZone.vue"
import inp from "@/Common/inp.vue"
import BtnDropdown from "@/Common/btnDropdown.vue"
import toggle from "@/Common/toggle.vue"
import editor from "@/Common/editor.vue"
import modal from "@/Common/modal.vue"
import Layout from "@/Common/Layout.vue"
import auth from "@/Common/Mixins/auth"
import avatarMixin from "@/Common/Mixins/avatar.js"
import tooltip from "@/Common/tooltip.vue"
import hgselect from "@/Common/hgselect.vue"
import activity from "@/Pages/Activity/Common/activity.vue"
import ActionsList from "@/Pages/Actions/List.vue"
import MacroModal from "@/Pages/Activity/Memo/MacroModal.vue"
import { Head, Link } from '@inertiajs/vue3'
import moment from "moment"
import axios from "axios"
import { get, findIndex } from "lodash"
import { nextTick } from "vue"
import alert from "../../Common/alert.vue"
import sanitizeHtml from 'sanitize-html';
import Cookies from 'js-cookie'
import TrelloCard from "./Memo/TrelloCard.vue"
import MemoContent from "./Memo/MemoContent.vue"

export default {
    name: 'ShowMemo',
    mixins: [auth, avatarMixin],
    components: {
        activity,
        avatar,
        btn, btnGroup,
        dropZone,
        BtnDropdown,
        editor,
        hgselect,
        inp,
        Head,
        Layout,
        Link,
        ActionsList,
        modal,
        toggle,
        alert,
        tooltip,
        MacroModal,
        TrelloCard,
        MemoContent
    },
    props: {
        macros: Array,
        auth: Object,
        memo: Object,
        relatedMemos: Array,
        activities: Array,
        clientsById: Object,
        usersById: Object,
        teamsById: Object,
        watching: Boolean,
        actions: Array,
        checklists: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            editorIsOpen: false,
            editing: {
                subject: false,
                description: false
            },
            changeStatus: {
                saving: false,
                generating: false,
                show: false,
                status: this.memo.status,
                note: ''
            },
            macroModal: {
                show: false,
                macros: []
            },
            newWatcher: {
                adding: false,
                email: ''
            },
            showWatchers: false,
            createTrelloCard: {
                connected: false,
                show: false,
                board: null,
                list: null,
                boards: [],
                lists: [],
                allLabels: []
            },
            m: {
                _reloading: false,
                id: this.memo.id,
                client_id: this.memo.client_id,
                subject: this.memo.subject,
                description: this.memo.description,
                status: this.memo.status,
                priority: this.memo.priority,
                assigned_to: this.memo.assigned_to,
                assigned_team: this.memo.assigned_team,
                is_internal: this.memo.is_internal,
                category: this.memo.category,
                watcher_count: this.memo.watcher_count,
                watchers: this.memo.watchers,
                meta: this.memo.meta
            },
            checkLists: this.checklists,
            checklistModal: {
                show: false,
                checklist: {
                    name: ''
                }
            },
            newChecklistItem: {
                show: false,
                checklistId: null,
                description: ''
            },
            showActivityDetails: false,
            allActivities: [
                ...this.activities
            ].reverse(),
            comment: {
                comment: '',
                attachments: []
            },
            isWatching: this.watching,
            postVisibility: (this.auth.user.type === 'team') ? 'private' : 'public',
            here: [],
            typing: {},
            typingIndicator: {
                dots: '',
                interval: null
            },
            userAuthToken: null,
            trelloCards: {}
        }
    },
    computed: {
        favicon() {
            if (this.memo.project && this.memo.project.favicon) {
                return this.memo.project.favicon
            } else if (this.memo.client && this.memo.client.favicon) {
                return this.memo.client.favicon
            }
            return null
        },
        trelloCardIds() {
            return this.allActivities.filter(a => a.type == 'trello_card').map(a => a.details.trello_card_id)
        },
        mentionablesForPost() {
            // If the ticket is internal or the post is private, then only team users can be mentioned
            if (this.m.is_internal || this.postVisibility == 'private') {
                return this.options.teamUsers
            }

            // Otherwise, no users can be mentioned
            return {}
        },
        frameUrl() {
            if (this.memo.meta && this.memo.meta.url) {
                const url = new URL(this.memo.meta.url);
                let host = url.origin;
                let path = url.pathname;
                let redirect = `${host}/mosaic/editor?mode=inline&path=${encodeURIComponent(path)}`;
                let token = this.userAuthToken;
                return `${host}/api/auth/relay?token=${token}&redirect=${encodeURIComponent(redirect)}`;
            }

            return null;
        },
        priorityLabels() {
            return {
                1: 'None',
                2: 'Low',
                3: 'Medium',
                4: 'High',
                5: 'Urgent',
                6: 'Critical'
            }
        },
        colors() {
            return {
                priorities: {
                    1: '#D5ECF4',
                    2: '#BBD8A0',
                    3: '#FFEF80',
                    4: '#FFD480',
                    5: '#FF9F80',
                    6: '#FF6F80'
                },
                statuses: {
                    "new": "#CEAADD",
                    "in-progress": "#95CCF1",
                    "need-more-information": "#FFCF83",
                    "waiting-on-client": "#FEE581",
                    "resolved": "#93E9B7",
                    "reopened": "#FF9F80"
                }
            }
        },
        canSaveStatus() {
            return this.changeStatus.status != this.memo.status && (this.changeStatus.status != 'resolved' || this.changeStatus.note)
        },
        canEditMemo() {
            const createdBy = get(this.memo, 'created_by.id');
            return createdBy && createdBy == get(this.auth, 'user.id')
        },
        canCreateMemo() {
            return !!(this.m.subject && this.m.description && this.m.client_id);
        },
        isTeamMember() {
            return this.auth.user.type === 'team';
        },
        clientFavicons() {
            const clientFavicons = {}
            const clients = Object.values(this.clientsById || {})
            clients.forEach(client => {
                if (client.favicon) {
                    clientFavicons[client.id] = client.favicon
                }
            })
            return clientFavicons;
        },
        options() {
            const clientNames = {}
            const clients = Object.values(this.clientsById || {})
            clients.forEach(client => {
                clientNames[client.id] = client.name
            })
            const userNames = {}
            const users = Object.values(this.usersById || {})
            users.forEach(user => {
                userNames[user.id] = user.name
            })
            const ourTeamUserNames = {}
            users.forEach(user => {
                if (user.type == 'team') {
                    ourTeamUserNames[user.id] = user.name
                }
            })
            const teamNames = {}
            const teams = Object.values(this.teamsById || {})
            teams.forEach(team => {
                teamNames[team.id] = team.name
            })

            const statuses = {
                'new': 'New',
                'in-progress': 'In Progress',
                'need-more-information': 'Need More Information',
                'waiting-on-client': 'Waiting on Client',
                'resolved': 'Resolved',
            };

            if (this.memo.status == 'resolved' || this.memo.status == 'reopened') {
                statuses['reopened'] =  'Reopened'
            }

            const categories = {
                support: 'Support',
                billing: 'Billing',
                notification: 'Notification',
                promotion: 'Promotion',
                spam: 'Spam',
                other: 'Other',
            }

            return {
                clients: clientNames,
                users: userNames,
                teamUsers: ourTeamUserNames,
                teams: teamNames,
                categories,
                priorities: {
                    1: 'None',
                    2: 'Low',
                    3: 'Medium',
                    4: 'High',
                    5: 'Urgent',
                    6: 'Critical'
                },
                statuses,
                trelloBoards: this.createTrelloCard.boards.reduce((acc, board) => {
                    acc[board.id] = board.name
                    return acc
                }, {}),
                trelloLists: this.createTrelloCard.lists.reduce((acc, list) => {
                    acc[list.id] = list.name
                    return acc
                }, {}),
                trelloLabels: this.createTrelloCard.allLabels.reduce((acc, label) => {
                    acc[label.id] = label.name ? `${label.name} (${label.color})` : label.color
                    return acc
                }, {}),
            }
        }
    },
    mounted() {
        if (this.auth.user.type === 'team') {
            this.loadTrelloCards();

            window.Echo.join(`memo.private.${this.memo.id}`)
                .here((users) => {
                    this.here = users.filter(user => user.id !== this.auth.user.id)
                })
                .joining((user) => {
                    if (user.id !== this.auth.user.id) {
                        this.here.push(user)
                    }
                })
                .leaving((user) => {
                    var idx = findIndex(this.here, {
                        id: user.id
                    })
                    this.here.splice(idx, 1)
                })
                .listen('MemoActivityDeleted', this.activityDeleted)
                .listen('MemoActivityCreated', this.activityCreated);
        }

        window.Echo.private(`memo.${this.memo.id}`)
            .listen('MemoActivityDeleted', this.activityDeleted)
            .listen('MemoActivityCreated', this.activityCreated)
            .listen('MemoUpdated', this.reloadMemo)
            .listenForWhisper('typing', (e) => {
                if (e.name in this.typing) {
                    clearTimeout(this.typing[e.name])
                }

                this.typing[e.name] = setTimeout(() => {
                    delete this.typing[e.name]
                }, 5000)
            })

        this.typingIndicator.interval = setInterval(() => {
            const length = (this.typingIndicator.dots.length + 1) % 4
            this.typingIndicator.dots = '.'.repeat(length)
        }, 250)
    },
    beforeUnmount() {
        window.Echo.leave(`memo.${this.memo.id}`)
        if (this.auth.user.type === 'team') {
            window.Echo.leave(`memo.private.${this.memo.id}`)
        }
        clearInterval(this.typingIndicator.interval)
    },
    methods: {
        openChecklistModal() {
            this.checklistModal.show = true;
        },
        async addChecklist() {
            const { data } = await axios.post(this.$route('activity.memo.checklist', this.memo.id), {
                name: this.checklistModal.checklist.name
            })
            this.checklistModal.show = false
            this.checklistModal.checklist.name = ''
            this.checkLists.push(data)
        },
        startNewChecklistItem(checklist) {
            this.newChecklistItem.show = true;
            this.newChecklistItem.checklistId = checklist.id;
            this.newChecklistItem.description = '';
            nextTick(() => {
                this.$refs.addItemInp[0].$refs.inp.focus()
            })
        },
        async deleteChecklist(checklist) {
            await axios.delete(this.$route('activity.memo.checklist.delete', {memo: this.memo.id, checklist: checklist.id}));
            this.checkLists = this.checkLists.filter(cl => cl.id !== checklist.id);
        },
        async addChecklistItem() {
            const checklist = this.checklists.find(checklist => checklist.id === this.newChecklistItem.checklistId);
            const description = this.newChecklistItem.description;
            if (!checklist || !description) {
                return;
            }

            this.newChecklistItem.description = '';

            if (!checklist.items) {
                checklist.items = [];
            }

            checklist.items.push({
                id: null,
                description,
                completed: false,
                completed_at: null,
            })

            const { data } = await axios.post(this.$route('activity.memo.checklist.item', {memo: this.memo.id, checklist: this.newChecklistItem.checklistId}), {
                description,
            })

            checklist.items.pop();
            checklist.items.push(data);

        },
        async updateChecklistItem(checklist, item) {
            await axios.post(this.$route('activity.memo.checklist.item.update', {memo: this.memo.id, checklist: checklist.id, item: item.id}), {
                description: item.description,
                completed: item.completed,
            })
        },
        async loadTrelloCards() {
            const { data } = await axios.get(this.$route('trello.check'))
            this.createTrelloCard.connected = data.result
            if (this.createTrelloCard.connected) {
                this.trelloCardIds.forEach(async id => {
                    if (!(id in this.trelloCards)) {
                        const card = await this.loadTrelloCard(id)
                        if (card) {
                            this.trelloCards[id] = card
                        }
                    }
                })
            }
        },
        async loadTrelloCard(id) {
            const { data } = await axios.get(this.$route('trello.card', id))
            return data
        },
        async createTheTrelloCard() {
            const { data } = await axios.post(this.$route('trello.create.card'), {
                board: this.createTrelloCard.board,
                list: this.createTrelloCard.list,
                labels: this.createTrelloCard.labels,
                name: this.m.subject,
                description: this.m.description,
                url: window.location.href
            })
            await this.addTrelloCardActivity(data.id, data.shortUrl)
            this.createTrelloCard.board = null
            this.createTrelloCard.list = null
            this.createTrelloCard.labels = []
            this.createTrelloCard.show = false
        },
        async loadTrelloBoards() {
            const { data } = await axios.get(this.$route('trello.boards'))
            this.createTrelloCard.boards = data
        },
        async loadTrelloLists(boardId) {
            this.createTrelloCard.lists = []
            const { data } = await axios.get(this.$route('trello.lists', boardId))
            this.createTrelloCard.lists = data
        },
        async loadTrelloLabels(boardId) {
            this.createTrelloCard.allLabels = []
            const { data } = await axios.get(this.$route('trello.labels', boardId))
            this.createTrelloCard.allLabels = data
        },
        async openEditor() {
            this.userAuthToken = await axios.get(this.$route('user.auth.token')).then(res => res.data.token);
            this.editorIsOpen = true;
        },
        useMacro(macro) {
            this.comment.comment = macro.body.content
            this.comment.attachments = macro.body.attachments
            this.macroModal.show = false
        },
        openMacroModal() {
            this.macroModal.show = true;
        },
        openPublishModal() {
            this.$refs.editorFrame.contentWindow.postMessage({
                action: 'publishNow'
            }, '*');
        },
        status(status) {
            const statuses = {
                'new': {
                    label: 'New',
                    icon: 'fa-fw fad fa-sparkles',
                    color: 'blue-500',
                },
                'in-progress': {
                    label: 'In Progress',
                    icon: 'fa-fw fad fa-cogs',
                    color: 'orange-500',
                },
                'need-more-information': {
                    label: 'Need More Information',
                    icon: 'fa-fw fad fa-message-question',
                    color: 'red-500',
                },
                'waiting-on-client': {
                    label: 'Waiting on Client',
                    icon: 'fa-fw fad fa-user-clock',
                    color: 'violet-500',
                },
                'resolved': {
                    label: 'Resolved',
                    icon: 'fa-fw fad fa-check-circle',
                    color: 'green-500',
                },
                'reopened': {
                    label: 'Reopened',
                    icon: 'fa-fw fad fa-exclamation-circle',
                    color: 'orange-500',
                }
            }
            return status in statuses ? statuses[status] : status
        },
        priority(priority) {
            const priorities = {
                '1': {
                    label: 'None',
                    icon: 'fa-fw fad fa-face-sleeping',
                    color: 'gray-500',
                },
                '2': {
                    label: 'Low',
                    icon: 'fa-fw fad fa-face-thinking',
                    color: 'blue-500',
                },
                '3': {
                    label: 'Medium',
                    icon: 'fa-fw fad fa-face-smile',
                    color: 'green-500',
                },
                '4': {
                    label: 'High',
                    icon: 'fa-fw fad fa-face-anxious-sweat',
                    color: 'orange-500',
                },
                '5': {
                    label: 'Urgent',
                    icon: 'fa-fw fad fa-face-scream',
                    color: 'red-500',
                },
                '6': {
                    label: 'Critical',
                    icon: 'fa-fw fad fa-siren',
                    color: 'red-500',
                }
            }
            return priority in priorities ? priorities[priority] :priority
        },
        async getResolutionMessage(threadId, count) {
            if (!threadId) {
                this.changeStatus.generating = true;
                const { data } = await axios.post(this.$route("ai.resolution", this.m.id))
                if (data.threadId) {
                    setTimeout(() => this.getResolutionMessage(data.threadId, 1), 500)
                }
                return;
            }

            const { data } = await axios.get(this.$route("ai.thread", threadId))
            if (!data || data.length === 0) {
                setTimeout(() => this.getResolutionMessage(threadId, count + 1), 500)
                return;
            }
            const mostRecentMessage = data[0]
            if (mostRecentMessage.role === 'assistant') {
                this.changeStatus.note = mostRecentMessage.content
                this.changeStatus.generating = false;
                return
            }

            if (count < 10) {
                setTimeout(() => this.getResolutionMessage(threadId, count + 1), 500)
            } else {
                this.changeStatus.generating = false;
            }
        },
        isTyping() {
            window.Echo.private(`memo.${this.memo.id}`)
                .whisper('typing', {
                    name: this.auth.user.name
                });
        },
        back() {
            const prevUrl = Cookies.get('previous-url')
            if (prevUrl.match(/^\/activity[^/]/)) {
                this.$inertia.visit(prevUrl)
            } else {
                this.$inertia.visit(this.$route('activity'))
            }
        },
        safe(html) {
            return sanitizeHtml(html, {
                disallowedTagsMode: 'recursiveEscape',
                transformTags: {
                    'a': function(tagName, attribs) {
                        return {
                            tagName,
                            attribs: {
                                ...attribs,
                                target: "_blank"
                            }
                        };
                    }
                }
            }).replace(/\n\s+/g, "\n");
        },
        assignToMe() {
            this.m.assigned_to = this.auth.user.id;
            this.m.assigned_team = this.auth.user.teams[0].id;
        },
        activityDeleted($event) {
            this.reloadMemo();
            const index = this.allActivities.findIndex(activity => {
                return activity.id === $event.activity.id
            })
            if (index >= 0) {
                this.allActivities.splice(index, 1);
            }
        },
        activityCreated($event) {
            this.reloadMemo()
            const index = this.allActivities.findIndex(activity => {
                return activity.id === $event.activity.id
            })
            if (index === -1) {
                this.allActivities.unshift($event.activity);
            }
        },
        reloadMemo() {
            this.$inertia.reload({
                only: ['memo', 'actions'],
            })
        },
        dt (value) {
            const date = moment.utc(value).local()
            if (date.isSame(moment(), 'day')) {
                return date.format('h:mma');
            } else if (date.isSame(moment(), 'year')) {
                return date.format('MMM Do, h:mma');
            }
            return date.format('MMMM Do YYYY, h:mma');
        },
        ago (value) {
            return moment.utc(value).fromNow();
        },
        async changeStatusWithComment(status) {
            this.post()

            this.changeStatus.status = status
            this.changeStatus.note = ''
            await this.saveStatus()
        },
        async saveStatus() {
            this.changeStatus.saving = true

            this.m.status = this.changeStatus.status;

            this.$emit('update:memo', {
                ...this.memo,
                status: this.changeStatus.status
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    status: this.changeStatus.status,
                    note: this.changeStatus.note
                }
            )

            this.allActivities.unshift(data);

            this.changeStatus.saving = false
            this.changeStatus.note = ''
            this.changeStatus.show = false
        },
        post() {
            if (this.postVisibility === 'public') {
                this.addComment();
            } else {
                this.addPrivateNote();
            }
        },
        changed($event, key) {
            this.editing[key] = false
            this.m[key] = $event.target.innerText
        },
        addAttachments(files) {
            if (files.length) {
                this.uploadFiles(files, false);
            }
        },
        uploadFiles(files = null, automaticallyPost = false) {
            const promises = (Array.isArray(files) ? files : Array.from(this.$refs.file.files)).map((file) => {
                // upload file from $refs.file
                const formData = new FormData();
                formData.append('file', file);
                // update to media.update using axios
                return axios.post(this.$route('media.upload'), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            });

            Promise.all(promises).then((responses) => {
                // clear the file input
                this.$refs.file.value = null;
                responses.forEach(({ data }) => {
                    this.comment.attachments.push(data);
                })
                if (automaticallyPost) {
                    this.addComment();
                }
            })
        },
        async createMemo() {
            if (this.canCreateMemo) {
                const { data } = await axios.post(
                    this.$route("activity.memo.store"),
                    {
                        subject: this.m.subject,
                        description: this.m.description,
                        client_id: this.m.client_id,
                        is_internal: this.m.is_internal,
                        status: this.m.status,
                        priority: this.m.priority,
                        category: this.m.category,
                        assigned_to: this.m.assigned_to,
                        assigned_team: this.m.assigned_team,
                    }
                )
                this.$inertia.visit(this.$route('activity.memo', data.id))
            }
        },
        async addPrivateNote() {
            if (!this.comment.comment && !this.comment.attachments.length) {
                return;
            }
            const { data } = await axios.post(
                this.$route("activity.store", this.m.id),
                {
                    type: 'note',
                    details: {
                        comment: this.comment.comment || `Uploaded ${this.comment.attachments.length} file${this.comment.attachments.length > 1 ? 's' : ''}`
                    },
                    attachments: this.comment.attachments
                }
            )

            this.comment.comment = '';
            this.comment.attachments = [];
            this.activityCreated({ activity: data })
            if (!this.isWatching) {
                this.watch()
            }
        },
        async addTrelloCardActivity(cardId, cardUrl) {
             const { data } = await axios.post(
                this.$route("activity.store", this.m.id),
                {
                    type: 'trello_card',
                    details: {
                        trello_card_id: cardId,
                        trello_card_url: cardUrl
                    }
                }
            )
            this.activityCreated({ activity: data })
        },
        async addComment() {
            if (!this.comment.comment && !this.comment.attachments.length) {
                return;
            }

            let type = 'comment';

            if (this.comment.attachments.length && !this.comment.comment) {
                type = 'attachment';
            }

            const { data } = await axios.post(
                this.$route("activity.store", this.m.id),
                {
                    type,
                    details: type === 'comment' ? {
                        comment: this.comment.comment
                    } : null,
                    attachments: this.comment.attachments
                }
            )

            this.comment.comment = '';
            this.comment.attachments = [];
            this.allActivities.unshift(data);
            if (!this.isWatching) {
                this.watch()
            }
        },
        async updateActivity(activity, idx, $event) {
            axios.post(this.$route('activity.update', [this.m.id, activity.id]), {
                details: $event.details
            });
        },
        async deleteActivity(activity, idx) {
            this.allActivities.splice(idx, 1);
            axios.delete(this.$route('activity.delete', [this.m.id, activity.id]));
        },
        async deleteAttachment(activity, idx, id) {
            const mIdx = activity.media.findIndex(media => {
                return media.id === id
            })

            if (mIdx >= 0) {
                activity.media.splice(mIdx, 1);
            }

            if (activity.media.length === 0 && activity.type == "attachment") {
                return this.deleteActivity(activity, idx)
            } else {
                axios.delete(this.$route('activity.media.delete', [this.m.id, activity.id, id]));
            }
        },
        async addWatcher(email) {
            if (!email || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                return;
            }
            const { data } = await axios.post(this.$route('activity.memo.watch', [this.m.id]), { email });
            this.m.watcher_count += 1
            this.m.watchers.push(data.user)
            this.newWatcher.email = ''
        },
        async watch() {
            axios.post(this.$route('activity.memo.watch', [this.m.id]));
            this.m.watcher_count += 1
            this.isWatching = true
            this.m.watchers.push(this.auth.user)
        },
        async unwatch(watcherId) {
            if (!(watcherId && confirm('Are you sure you want to remove this watcher from this memo?'))) {
                return;
            }
            const data = watcherId ? { watcher_id: watcherId } : {}
            axios.delete(this.$route('activity.memo.unwatch', [this.m.id]), { data });

            this.m.watcher_count -= 1
            if (!watcherId || watcherId === this.auth.user.id) {
                this.isWatching = false
            }

            this.m.watchers = this.m.watchers.filter(watcher => watcher.id !== watcherId)
        }

    },
    watch: {
        trelloCardIds() {
            this.loadTrelloCards()
        },
        'createTrelloCard.board': function(val) {
            this.loadTrelloLists(val)
            this.loadTrelloLabels(val)
        },
        'changeStatus.status': function(val) {
            if (val === 'resolved') {
                this.getResolutionMessage()
            }
        },
        'memo': function() {
            this.m._reloading = true;
            this.m.client_id = this.memo.client_id;
            this.m.is_internal = this.memo.is_internal;
            this.m.subject = this.memo.subject;
            this.m.description = this.memo.description;
            this.m.status = this.memo.status;
            this.m.category = this.memo.category;
            this.m.priority = this.memo.priority;
            this.m.assigned_to = this.memo.assigned_to;
            this.m.assigned_team = this.memo.assigned_team;
            this.m.watchers = this.memo.watchers;
            this.m.meta = this.memo.meta;

            nextTick(() => {
                this.m._reloading = false;
            })
        },
        'm.assigned_to': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                assigned_to: val
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    assigned_to: this.m.assigned_to
                }
            )

            this.allActivities.unshift(data);
        },
        'm.is_internal': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                is_internal: val
            });

            await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    is_internal: this.m.is_internal
                }
            )
        },
        'm.assigned_team': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                assigned_team: val
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    assigned_team: this.m.assigned_team
                }
            )

            this.allActivities.unshift(data);
        },
        'm.client_id': async function (val) {
            if (this.m._reloading || !this.m.id) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                client_id: val
            });

            await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    client_id: this.m.client_id
                }
            )

            // extract domain name from the url
            const url = get(this.memo, 'meta.url', null)
            if (url) {
                const domain = url.replace(/(^\w+:|^)\/\//, '').replace(/\/.*$/, '');

                const {data: projectsForClient} = await axios.get(this.$route('clients.projects', this.m.client_id));

                if (projectsForClient.length == 0) {
                    return;
                }

                for (let project of projectsForClient) {
                    if ((project.domains || []).includes(domain)) {
                        break; //already associated with the domain
                    }
                }

                let projectId = null;

                if (projectsForClient.length == 1) {
                    if (confirm(`Would you like all future feedback from ${domain} to be associated with ${projectsForClient[0].name}?`)) {
                        projectId = projectsForClient[0].id;
                    }
                } else {
                    for (let project of projectsForClient) {
                        if (confirm(`Would you like all future feedback from ${domain} to be associated with ${project.name}?`)) {
                            projectId = project.id;
                            break;
                        }
                    }
                }

                if (projectId) {
                    await axios.post(
                        this.$route('projects.domain', projectId),
                        { domain }
                    )
                }
            }
        },
        'm.priority': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                priority: val
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    priority: this.m.priority
                }
            )

            this.allActivities.unshift(data);
        },
        'm.category': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                category: val
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    category: this.m.category
                }
            )

            this.allActivities.unshift(data);
        },
        'm.status': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                status: val
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    status: val
                }
            )

            this.allActivities.unshift(data);
        },
        'm.subject': async function (val) {
            if (this.m._reloading) {
                return;
            }

            if (this.canEditMemo) {
                this.$emit('update:memo', {
                    ...this.memo,
                    subject: val
                });

                await axios.post(
                    this.$route("activity.memo.update", this.m.id),
                    {
                        '_method': 'PUT',
                        subject: this.m.subject
                    }
                )
            }
        },
        'm.description': async function (val) {
            if (this.m._reloading) {
                return;
            }

            if (this.canEditMemo) {
                this.$emit('update:memo', {
                    ...this.memo,
                    description: val
                });

                await axios.post(
                    this.$route("activity.memo.update", this.m.id),
                    {
                        '_method': 'PUT',
                        description: this.m.description
                    }
                )
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
:deep(span.mention, span.suggestion) {
    background-color: #f1f2f3;
    padding: 0.15rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 1rem;
}
</style>
