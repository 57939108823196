<template>
    <div>
        <form role="search" @submit.prevent="doSearch()" novalidate="" class="relative flex items-center pr-4" :class="search ? 'shadow-sm' : ''">
            <input
                v-model="search"
                type="search"
                ref="input"
                autofocus
                aria-autocomplete="list" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                style="caret-color: #6b7280"
                class="flex-auto -mr-9 appearance-none bg-transparent pl-4 pr-12 py-4 text-gray-600 text-base sm:text-sm placeholder-gray-500 focus:outline-none"
                placeholder="Find anything..."
            />
            <svg width="20" height="20" fill="none" class="flex-none text-gray-400 pointer-events-none">
                <circle cx="8.5" cy="8.5" r="5.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></circle>
                <path d="M17.25 17.25L13 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        </form>
        <div class="p-4" v-if="results">
            <div class="hidden sm:block mb-4">
                <nav class="flex space-x-4" aria-label="Tabs">
                    <a
                        href="#"
                        @click.stop.prevent='active = "memos"'
                        class="px-3 py-2 font-medium text-sm rounded-md"
                        :class="{
                            'text-gray-500 hover:text-gray-700': active !== 'memos',
                            'bg-gray-100 text-gray-700': active == 'memos',
                        }"
                    >
                        Memos ({{ results.memos.total || 0 }})
                    </a>
                    <a
                        href="#"
                        @click.stop.prevent='active = "sprints"'
                        class="px-3 py-2 font-medium text-sm rounded-md"
                        :class="{
                            'text-gray-500 hover:text-gray-700': active !== 'sprints',
                            'bg-gray-100 text-gray-700': active == 'sprints',
                        }"
                    >
                        Work Orders ({{ results.sprints.total || 0 }})
                    </a>
                    <a
                        href="#"
                        @click.stop.prevent='active = "payments"'
                        class="px-3 py-2 font-medium text-sm rounded-md"
                        :class="{
                            'text-gray-500 hover:text-gray-700': active !== 'payments',
                            'bg-gray-100 text-gray-700': active == 'payments',
                        }"
                    >
                        Payments ({{ results.payments.total || 0 }})
                    </a>
                    <a
                        href="#"
                        @click.stop.prevent='active = "entries"'
                        class="px-3 py-2 font-medium text-sm rounded-md"
                        :class="{
                            'text-gray-500 hover:text-gray-700': active !== 'entries',
                            'bg-gray-100 text-gray-700': active == 'entries',
                        }"
                    >
                        Time Entries ({{ results.entries.total || 0 }})
                    </a>
                    <a
                        href="#"
                        @click.stop.prevent='active = "clients"'
                        class="px-3 py-2 font-medium text-sm rounded-md"
                        :class="{
                            'text-gray-500 hover:text-gray-700': active !== 'clients',
                            'bg-gray-100 text-gray-700': active == 'clients',
                        }"
                    >
                        Clients ({{ results.clients.total || 0 }})
                    </a>
                    <a
                        href="#"
                        @click.stop.prevent='active = "users"'
                        class="px-3 py-2 font-medium text-sm rounded-md"
                    >
                        Users ({{ results.users.total || 0 }})
                    </a>
                </nav>
            </div>

            <div v-if="active == 'payments' && results && results.payments && results.payments.total">
                <div class="flow-root mt-6">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li class="p-2 odd:bg-gray-100" v-for="payment in results.payments.data" :key="payment.id">
                            <div class="relative focus-within:ring-2 focus-within:ring-blue-500">
                                <h3 class="text-sm font-semibold text-gray-800">
                                    <Link
                                        :href='"/payments/" + payment.id'
                                        class="hover:underline focus:outline-none block truncate">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        {{ payment.number }} &mdash; {{ payment.client.name }}
                                    </Link>
                                </h3>
                            </div>
                        </li>
                    </ul>
                </div>
                <h2 class='pb-2 text-center mt-2'>
                    <strong>{{ results.payments.from }} - {{ results.payments.to }}</strong>
                    of
                    <strong>{{ results.payments.total }}</strong>
                    Payment{{ results.payments.total == 1 ? '' : 's' }}
                </h2>
                <div class="mt-6 flex">
                    <a
                        href="#"
                        @click.prevent="prevPage('payments')"
                        v-if="results.payments.current_page > 1"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Previous
                    </a>
                    <a
                        href="#"
                        @click.prevent="nextPage('payments')"
                        v-if="results.payments.current_page < results.payments.last_page"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Next
                    </a>
                </div>
            </div>
            <div v-if="active == 'sprints' && results && results.sprints && results.sprints.total">
                <div class="flow-root mt-6">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li class="p-2 odd:bg-gray-100" v-for="sprint in results.sprints.data" :key="sprint.id">
                            <div class="relative focus-within:ring-2 focus-within:ring-blue-500">
                                <h3 class="text-sm font-semibold text-gray-800">
                                    <Link
                                        :href='"/sprints/" + sprint.id'
                                        class="hover:underline focus:outline-none block truncate">
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        {{ sprint.name }} &mdash; {{ sprint.client.name }}
                                    </Link>
                                </h3>
                            </div>
                        </li>
                    </ul>
                </div>
                <h2 class='pb-2 text-center mt-2'>
                    <strong>{{ results.sprints.from }} - {{ results.sprints.to }}</strong>
                    of
                    <strong>{{ results.sprints.total }}</strong>
                    Work Order{{ results.sprints.total == 1 ? '' : 's' }}
                </h2>
                <div class="mt-6 flex">
                    <a
                        href="#"
                        @click.prevent="prevPage('sprints')"
                        v-if="results.sprints.current_page > 1"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Previous
                    </a>
                    <a
                        href="#"
                        @click.prevent="nextPage('sprints')"
                        v-if="results.sprints.current_page < results.sprints.last_page"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Next
                    </a>
                </div>
            </div>
            <div v-if="active == 'entries' && results && results.entries && results.entries.total">
                <div class="flow-root mt-6">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li class="py-3" v-for="entry in results.entries.data" :key="entry.id">
                            <div class="relative focus-within:ring-2 focus-within:ring-blue-500">
                                <h3 class="text-sm font-semibold text-gray-800">
                                    <Link
                                        :href='"/?week=" + entry.week'
                                        class="hover:underline focus:outline-none block truncate">
                                        <!-- Extend touch target to entire panel -->
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        {{ entry.week }} &mdash; {{ entry.user.name }} &mdash; {{ entry.client.name }}
                                    </Link>
                                </h3>
                                <p class="mt-1 text-xs text-gray-600">
                                    {{ entry.description }}
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <h2 class='pb-2 text-center mt-2'>
                    <strong>{{ results.entries.from }} - {{ results.entries.to }}</strong>
                    of
                    <strong>{{ results.entries.total }}</strong>
                    Time Entr{{ results.entries.total == 1 ? 'y' : 'ies' }}
                </h2>
                <div class="mt-6 flex">
                    <a
                        href="#"
                        @click.prevent="prevPage('entries')"
                        v-if="results.entries.current_page > 1"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Previous
                    </a>
                    <a
                        href="#"
                        @click.prevent="nextPage('entries')"
                        v-if="results.entries.current_page < results.entries.last_page"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Next
                    </a>
                </div>
            </div>
            <div v-if="active == 'clients' && results && results.clients && results.clients.total">
                <div class="flow-root mt-6">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li class="py-1" v-for="client in results.clients.data" :key="client.id">
                            <div class="relative focus-within:ring-2 focus-within:ring-blue-500">
                                <h3 class="text-sm font-semibold text-gray-800">
                                    <Link
                                        :href='"/clients/" + client.id'
                                        class="hover:underline focus:outline-none block truncate">
                                        <!-- Extend touch target to entire panel -->
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        {{ client.name }}
                                    </Link>
                                </h3>
                            </div>
                        </li>
                    </ul>
                </div>
                <h2 class='pb-2 text-center mt-2'>
                    <strong>{{ results.clients.from }} - {{ results.clients.to }}</strong>
                    of
                    <strong>{{ results.clients.total }}</strong>
                    Client{{ results.clients.total == 1 ? '' : 's' }}
                </h2>
                <div class="mt-6 flex">
                    <a
                        href="#"
                        @click.prevent="prevPage('clients')"
                        v-if="results.clients.current_page > 1"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Previous
                    </a>
                    <a
                        href="#"
                        @click.prevent="nextPage('clients')"
                        v-if="results.clients.current_page < results.clients.last_page"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Next
                    </a>
                </div>
            </div>
            <div v-if="active == 'users' && results && results.users && results.users.total">
                <div class="flow-root mt-6">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li class="py-1" v-for="user in results.users.data" :key="user.id">
                            <div class="relative focus-within:ring-2 focus-within:ring-blue-500">
                                <h3 class="text-sm font-semibold text-gray-800">
                                    <Link
                                        :href='"/users/" + user.id'
                                        class="hover:underline focus:outline-none block truncate">
                                        <!-- Extend touch target to entire panel -->
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        {{ user.name }}
                                        <span class="text-xs text-gray-500" v-if="user.email">
                                            - {{ user.email }}
                                        </span>
                                        <span class="text-xs text-gray-500" v-if="user.phone && user.phone !== 'null'">
                                            - {{ user.phone }}
                                        </span>
                                    </Link>
                                </h3>
                            </div>
                        </li>
                    </ul>
                </div>
                <h2 class='pb-2 text-center mt-2'>
                    <strong>{{ results.users.from }} - {{ results.users.to }}</strong>
                    of
                    <strong>{{ results.users.total }}</strong>
                    User{{ results.users.total == 1 ? '' : 's' }}
                </h2>
                <div class="mt-6 flex">
                    <a
                        href="#"
                        @click.prevent="prevPage('users')"
                        v-if="results.users.current_page > 1"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Previous
                    </a>
                    <a
                        href="#"
                        @click.prevent="nextPage('users')"
                        v-if="results.users.current_page < results.users.last_page"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Next
                    </a>
                </div>
            </div>
            <div v-if="active == 'memos' && results && results.memos && results.memos.total">
                <div class="flow-root mt-6">
                    <ul role="list" class="divide-y divide-gray-200">
                        <li class="py-1" v-for="memo in results.memos.data" :key="memo.id">
                            <div class="relative focus-within:ring-2 focus-within:ring-blue-500">
                                <h3 class="text-sm font-semibold text-gray-800">
                                    <Link
                                        :href='"/activity/" + memo.ticket_number'
                                        class="hover:underline focus:outline-none block truncate">
                                        <!-- Extend touch target to entire panel -->
                                        <span class="absolute inset-0" aria-hidden="true"></span>
                                        <span
                                            :class="'inline-flex gap-x-1 items-center text-sm font-medium text-' + status(memo.status).color"
                                        >
                                            <i
                                                :class="{
                                                    [status(memo.status).color]: true,
                                                    [status(memo.status).icon]: true
                                                }"
                                            ></i>
                                            {{ status(memo.status).label }}
                                        </span>
                                        {{ memo.ticket_number }} &mdash; <span v-if='memo.client'>{{ memo.client.name }}</span> &mdash; <span v-html='memo.subject'></span>
                                    </Link>
                                </h3>
                                <p class="my-2 text-xs text-gray-600" v-html='memo.description'></p>
                                <div class="text-xs text-gray-600 max-h-24 overflow-y-auto bg-gray-50 p-2">
                                    <template v-for="comment in memo.comments" :key="comment.id">
                                        <p v-html="comment"></p>
                                    </template>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <h2 class='pb-2 text-center mt-2'>
                    Page {{ results.memos.currentPage }} of {{ results.memos.lastPage }}
                    <br><strong>{{ results.memos.total }}</strong>
                    Memo{{ results.memos.total == 1 ? '' : 's' }}
                </h2>
                <div class="mt-6 flex">
                    <a
                        href="#"
                        @click.prevent="prevPage('memos')"
                        v-if="results.memos.current_page > 1"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Previous
                    </a>
                    <a
                        href="#"
                        @click.prevent="nextPage('memos')"
                        v-if="results.memos.current_page < results.memos.last_page"
                        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-2"
                    >
                        Next
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { debounce } from "lodash"
import { Link } from '@inertiajs/vue3'

export default {
    components: { Link },
    data() {
        return {
            search: '',
            active: 'memos',
            results: null,
            colors: {
                statuses: {
                    "new": "#CEAADD",
                    "in-progress": "#95CCF1",
                    "need-more-information": "#FFCF83",
                    "waiting-on-client": "#FEE581",
                    "resolved": "#93E9B7",
                    "reopened": "#FF9F80"
                }
            }
        }
    },
    methods: {
        status(status) {
            const statuses = {
                'new': {
                    label: 'New',
                    icon: 'fad fa-sparkles',
                    color: 'blue-500',
                },
                'in-progress': {
                    label: 'In Progress',
                    icon: 'fad fa-cogs',
                    color: 'orange-500',
                },
                'need-more-information': {
                    label: 'Need More Information',
                    icon: 'fad fa-message-question',
                    color: 'red-500',
                },
                'waiting-on-client': {
                    label: 'Waiting on Client',
                    icon: 'fad fa-user-clock',
                    color: 'violet-500',
                },
                'resolved': {
                    label: 'Resolved',
                    icon: 'fad fa-check-circle',
                    color: 'green-500',
                },
                'reopened': {
                    label: 'Reopened',
                    icon: 'fa-fw fad fa-exclamation-circle',
                    color: 'orange-500',
                }
            }
            return status in statuses ? statuses[status] : {
                label: status,
                icon: 'fad fa-question-circle',
                color: 'gray-500',
            }
        },
        focus() {
            this.$refs.input.focus()
        },
        doSearch: debounce(function() {
            if (this.search) {
                axios.post(this.$route("search"), {query: this.search})
                    .then(({data}) => {
                        this.results = data
                    })
            }
        }, 500),
        nextPage(attr) {
            axios.post(
                this.$route("search"), {
                    query: this.search,
                    page: this.results[attr].current_page + 1,
                    only: [attr]
                }).then(({data}) => {
                    this.results[attr] = data[attr]
                })
        },
        prevPage(attr) {
            axios.post(
                this.$route("search"), {
                    query: this.search,
                    page: this.results[attr].current_page - 1,
                    only: [attr]
                }).then(({data}) => {
                    this.results[attr] = data[attr]
                })
        }
    },
    watch: {
        search() {
            this.doSearch()
        }
    }
}
</script>

<style>

</style>
