<template>
    <Layout :auth='auth'>
        <Head :title="client.name" />
        <template v-slot:title>{{ client.name }}</template>
        <template v-slot:actions>
            <btn class="mr-4" white outline @click="startMerge = true">
                <i class="fa fa-spinner-third fa-spin" v-if="merging"></i>
                Merge Client
            </btn>
            <btn class="mr-4" red @click="archive">
                <i class="fa fa-spinner-third fa-spin" v-if="archiving"></i>
                Archive Client
            </btn>
            <btn white :href="$route('clients.edit', client.id)">
                Edit Client
            </btn>
        </template>

        <div class="grid grid-cols-2 items-stretch gap-4">
            <div>
                <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        <div class="flex items-center flex-wrap">
                            <span class="flex-grow">Client Information</span>
                            <img
                                v-if="client.logo"
                                :src="$url(client.logo)"
                                class="h-8 object-cover"
                            />
                        </div>
                    </h3>
                </div>
                <div class="flex flex-col gap-4">
                    <dl>
                        <div
                            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                            v-if="client.name"
                        >
                            <dt class="text-sm leading-5 font-medium text-gray-500">
                                Name
                            </dt>
                            <dd
                                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                                {{ client.name }}
                            </dd>
                        </div>
                        <div
                            class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                            v-if="client.tags && client.tags.length"
                        >
                            <dt class="text-sm leading-5 font-medium text-gray-500">
                                Tags
                            </dt>
                            <dd
                                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                                {{ client.tags.join(", ") }}
                            </dd>
                        </div>
                        <div
                            class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                            v-if="client.website"
                        >
                            <dt class="text-sm leading-5 font-medium text-gray-500">
                                Website
                            </dt>
                            <dd
                                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                                {{ client.website }}
                            </dd>
                        </div>
                        <div
                            class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                            v-if="client.description"
                        >
                            <dt class="text-sm leading-5 font-medium text-gray-500">
                                Description
                            </dt>
                            <dd
                                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                                {{ client.description }}
                            </dd>
                        </div>
                        <div
                            class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                            v-if="client.contact_name || client.contact_email || client.contact_phone"
                        >
                            <dt class="text-sm leading-5 font-medium text-gray-500">
                                Client Contact
                            </dt>
                            <dd
                                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                                <div v-if="client.contact_name">
                                    <i class="fa fa-user text-gray-400"></i>
                                    <span class="ml-2 flex-1 w-0 truncate">
                                        {{ client.contact_name }}
                                    </span>
                                </div>
                                <div v-if="client.contact_name">
                                    <i class="fa fa-envelope text-gray-400"></i>
                                    <span class="ml-2 flex-1 w-0 truncate">
                                        {{ client.contact_email }}
                                    </span>
                                </div>
                                <div v-if="client.contact_phone">
                                    <i class="fa fa-phone text-gray-400"></i>
                                    <span class="ml-2 flex-1 w-0 truncate">
                                        {{ client.contact_phone }}
                                    </span>
                                </div>
                            </dd>
                        </div>
                        <div
                            class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                            v-if="client.rates"
                        >
                            <dt class="text-sm leading-5 font-medium text-gray-500">
                                Client Rates
                            </dt>
                            <dd
                                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                                <table>
                                    <tr v-for='(rate, key) in client.rates' :key='"rate-" + key'>
                                        <td class="font-medium pr-2">{{ type(key, 'name', key) }}</td>
                                        <td>${{ client.rates[key] }}</td>
                                    </tr>
                                </table>
                            </dd>
                        </div>
                    </dl>


                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2 pb-2 border-b border-gray-200 flex justify-between">
                            <span>Projects</span>
                            <btn small outline :href="$route('projects.create', client.id)">
                                Add New Project
                            </btn>
                        </h3>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                            <project-card v-for="project in projects" :key="project.id" :project="project" />
                        </div>
                    </div>

                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2 pb-2 border-b border-gray-200 flex justify-between">
                            <span>Users</span>
                            <btn small outline @click="startAddingNewUser()">
                                Add New User
                            </btn>
                        </h3>
                        <div class="flex flex-col" v-if="users && users.length">
                            <users-table
                                :client='client'
                                :users='users'
                                editable
                            />
                        </div>
                        <div v-else-if="users === null">
                            Loading... <i class="fa fa-spinner-third fa-spin"></i>
                        </div>
                        <div v-else class="p-2">
                            <alert color="green">
                                This client doesn't have any users yet.
                            </alert>
                        </div>
                    </div>

                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2 pb-2 border-b border-gray-200 flex justify-between">
                            <span>Attachments</span>
                            <btn small outline @click="$refs.file.click()">
                                Add New Attachment
                            </btn>
                        </h3>
                        <section>
                            <div v-if="media.length" class="mb-3">
                                <label for="attachments" class="block mt-2 mb-1 text-sm font-medium leading-5 text-gray-700">Attachments:</label>
                                <ul class="flex flex-wrap gap-2 text-sm">
                                    <li
                                        v-for="(attachment, idx) in media"
                                        :key="'attachment-' + attachment.id"
                                    >
                                        <a :href="attachment.fullUrl" target="_blank" class="text-blue-600 hover:underline">
                                            <i v-if="attachment.type.indexOf('image') !== -1" class="fa fa-image"></i>
                                            <i v-else-if="attachment.type.indexOf('video') !== -1" class="fa fa-video"></i>
                                            <i v-else-if="attachment.type.indexOf('audio') !== -1" class="fa fa-music"></i>
                                            <i v-else-if="attachment.type.indexOf('pdf') !== -1" class="fa fa-file-pdf"></i>
                                            <i v-else class="fa fa-file"></i>
                                            {{ attachment.fileName }}
                                        </a>
                                        <span v-if="idx < media.length - 1">,</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-else class="mt-4">
                                <alert color="yellow">
                                    This client doesn't have any attachments.
                                </alert>
                            </div>
                            <input
                                    ref="file"
                                type="file"
                                class="hidden"
                                multiple
                                @change="uploadFiles"
                            />
                        </section>
                    </div>
                </div>
            </div>

            <div class="bg-white p-4">
                <div class="flex flex-col gap-5">
                    <template v-if="hasPermission('manage:clients')">
                        <div class="flex gap-2 items-start">
                            <div class="flex flex-col gap-2 w-full">
                                <hgselect
                                    v-if="projects.length > 1"
                                    v-model="newNote.project_id"
                                    :options="projects.reduce((acc, p) => {
                                        acc[p.id] = p.name;
                                        return acc;
                                    }, {})"
                                />
                                <editor v-model="newNote.note" class="w-full new-note" />
                            </div>
                            <btn class="whitespace-nowrap" @click="addClientNote">Add Note</btn>
                        </div>
                    </template>
                    <div class="flex items-center flex-wrap justify-center gap-2">
                        <template v-for="(val, filter) in filters" :key="filter">
                            <span
                                class="rounded-full px-2 py-1 text-sm border border-gray-300"
                                :style="{
                                    'backgroundColor': filters[filter] ? filterColors[filter] : 'white',
                                    'color': filters[filter] ? 'black' : 'gray'
                                }"
                                @click="filterClick($event, filter)"
                            >
                                {{ translateFilterName(filter) }}
                            </span>
                        </template>
                    </div>
                    <div class="flex flex-col gap-2 overflow-auto max-h-[calc(100vh-30rem)]">
                        <template v-for="log in activityLog" :key="log.data.id">
                            <LogLine :log="log" :usersById="usersById" />
                        </template>
                    </div>
                    <template v-if="activeTab == 'activity'">
                        <div class="py-5 sm:p-0">
                            <section v-if="memos && memos.length">
                                <div class="flex flex-col divide-y divide-gray-200 border-b border-gray-200" v-if="memos && memos.length">
                                    <MemoRow
                                        v-for='memo in memos'
                                        :key="'memo-' + memo.id"
                                        :memo="memo"
                                        :usersById="usersById"
                                        :teamsById="teamsById"
                                        no-links
                                    />
                                </div>
                                <div class="mt-4 mr-2 text-right">
                                    <Link
                                        :href="`/activity?client=${client.id}`"
                                        class="text-sm text-blue-600 hover:text-blue-800"
                                    >
                                        View All Feedback
                                    </Link>
                                </div>
                            </section>
                            <div v-else-if="memos === null">
                                Loading... <i class="fa fa-spinner-third fa-spin"></i>
                            </div>
                            <div v-else class="py-2">
                                <alert color="green">
                                    This client doesn't have any Activity yet.
                                </alert>
                            </div>
                        </div>
                    </template>

                    <template v-if="activeTab == 'sprints'">
                        <div>
                            <div class="flex flex-col" v-if="sprints && sprints.length">
                                <sprints-table
                                    :show-client="false"
                                    :show-project="projects.length > 1"
                                    projects="projects"
                                    :sprints="sprints"
                                    @saved='sprintUpdated'
                                />
                            </div>
                            <div v-else-if="sprints === null">
                                Loading... <i class="fa fa-spinner-third fa-spin"></i>
                            </div>
                            <alert v-else color="green">
                                This client hasn't had any work orders yet.
                            </alert>
                        </div>
                    </template>

                    <template v-if="activeTab == 'payments'">
                        <payments-table
                            :auth='auth'
                            :payments='payments'
                            :projects='projects'
                            :editable="true"
                            :local-ordering="false"
                            :show-client="false"
                        />
                    </template>

                    <template v-if="activeTab == 'time-entries'">
                        <div class="">
                            <div class="flex flex-col" v-if="timeEntries && timeEntries.length">
                                <entries-table
                                    show-week
                                    show-sprint
                                    show-type
                                    :types="typeOptions"
                                    :entries='timeEntries'
                                />
                            </div>
                            <div v-else-if="timeEntries === null">
                                Loading... <i class="fa fa-spinner-third fa-spin"></i>
                            </div>
                            <alert v-else color="green">
                                This client hasn't had any time logged yet.
                            </alert>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <modal :show="startMerge" @close='startMerge = false' overflow-visible>
            <p class="mb-2">Merge this client into another one. All of this clients time entries, users, projects, payments, etc will be copied over to the other one.</p>
            <p class="italic mb-4">The other client will retain all of it's details and contact information.</p>
            <p class="font-bold mb-4">{{ client.name }} will be archived and it's details will be lost.</p>
            <div>
                <label for="client" class="block text-sm leading-5 font-medium text-gray-700">Merge <b>{{ client.name }}</b> Into...</label>
                <hgselect
                    id="client"
                    v-model="clientToMergeWith"
                    class="mt-1"
                    :options="otherClients"
                ></hgselect>
                <btn class="mt-4" red @click="doMerge" :disabled="!clientToMergeWith">
                    <i class="fa fa-spinner-third fa-spin" v-if="archiving"></i>
                    Merge Client
                </btn>
            </div>
        </modal>

        <modal :show="!!newUser" @close="newUser = false">
            <userForm
                v-if="newUser"
                v-model="newUser"
                @saved="userSaved()"
                @cancel="newUser = false"
            />
        </modal>
    </Layout>
</template>

<script>
import LogLine from "./LogLine.vue"
import auth from "../../Common/Mixins/auth"
import Layout from "../../Common/Layout.vue"
import alert from "../../Common/alert.vue"
import btn from "../../Common/btn.vue"
import sprintsTable from "../../Common/sprintsTable.vue"
import paymentsTable from "../../Common/paymentsTable.vue"
import entriesTable from "../../Common/entriesTable.vue"
import usersTable from "../../Common/usersTable.vue"
import projectCard from "../../Common/projectCard.vue"
import userForm from "../../Common/userForm.vue"
import hgselect from "../../Common/hgselect.vue"
import modal from "../../Common/modal.vue"
import editor from "../../Common/editor.vue"
import MemoRow from "../Activity/Common/memoRow.vue"
import axios from "axios"
import { clone, find, get } from "lodash"
import { Head, Link } from '@inertiajs/vue3'
import moment from "moment"

export default {
    components: {
        Layout, btn, alert, modal, hgselect, entriesTable,
        sprintsTable, usersTable, userForm, paymentsTable,
        MemoRow, Link, Head, projectCard, editor, LogLine
    },
    mixins: [ auth ],
    props: {
        auth: Object,
        client: {
            type: Object,
            default: null
        },
        users: Array,
        clients: Object,
        types: Array,
        projects: Array,
        usersById: Object,
        teamsById: Object
    },
    data() {
        return {
            newNote: {
                note: "",
                project_id: this.projects && this.projects.length > 0 ? this.projects[0].id : null
            },
            showAllWorkOrders: true,
            newUser: false,
            archiving: false,
            merging: false,
            startMerge: false,
            clientToMergeWith: null,
            filters: {
                activity: true,
                workOrders: true,
                payments: true,
                timeEntries: true,
                notes: true,
            },
            memos: null,
            payments: null,
            sprints: null,
            timeEntries: null,
            notes: null,
            media: this.client ? this.client.media : [],
        }
    },
    mounted() {
        this.loadActivityLog();
    },
    computed: {
        filterColors() {
            return {
                "activity": "#fbebf5",
                "payments": "#f4feec",
                "timeEntries": "#eaebfd",
                "workOrders": "#ecf5fd",
                "notes": "#fefcea",
            }
        },
        isTeamMember() {
            return this.auth.user.type == 'team'
        },
        activeSprints() {
            return this.sprints.filter((sprint) => {
                return sprint.status == 'active'
            })
        },
        typeOptions() {
            const types = {}
            this.types.forEach((type) => {
                types[type.slug] = type.name
            })
            return types
        },
        otherClients() {
            const clients = clone(this.clients)
            delete clients[this.client.id];
            return clients
        },
        activityLog() {
            // Combine all of the activity, work orders, payments, etc into a single array
            // ordered by date descending
            const log = []
            if (this.filters.activity) {
                log.push(...(this.memos || []).map((memo) => {
                    return {
                        data: memo,
                        type: 'memo'
                    }
                }))
            }
            if (this.filters.payments) {
                log.push(...(this.payments || []).map((payment) => {
                    return {
                        data: payment,
                        type: 'payment'
                    }
                }))
            }
            if (this.filters.timeEntries) {
                log.push(...(this.timeEntries || []).map((entry) => {
                    return {
                        data: entry,
                        type: 'timeEntry'
                    }
                }))
            }
            if (this.filters.workOrders) {
                log.push(...(this.sprints || []).map((sprint) => {
                    return {
                        data: sprint,
                        type: 'sprint'
                    }
                }))
            }
            if (this.filters.notes) {
                log.push(...(this.notes || []).map((note) => {
                    return {
                        data: note,
                        type: 'note'
                    }
                }))
            }

           // sort by date descending
           return log.sort((a, b) => {
               return moment(b.data.created_at).diff(moment(a.data.created_at))
           })
        }
    },
    methods: {
        filterClick($event, filter) {
            if ($event.metaKey) {
                // set all to false
                Object.keys(this.filters).forEach((key) => {
                    this.filters[key] = false
                })
                this.filters[filter] = true
            } else {
                this.filters[filter] = !this.filters[filter]
            }
        },
        translateFilterName(filter) {
            return {
                activity: 'Activity',
                workOrders: 'Work Orders',
                payments: 'Payments',
                timeEntries: 'Time Entries',
                notes: 'Notes',
            }[filter]
        },
        dateFormat(date) {
            if (date) {
                return moment(date.split('T')[0]).format("MMM D, YYYY")
            }
            return '--'
        },
        loadActivityLog() {
            axios.get(this.$route('clients.notes', this.client.id))
                .then((response) => {
                    this.notes = response.data
                });
            axios.get(this.$route('clients.memos', this.client.id))
                .then((response) => {
                    this.memos = response.data
                });
            axios.get(this.$route('clients.payments', this.client.id))
                .then((response) => {
                    this.payments = response.data
                });
            axios.get(this.$route('clients.sprints', this.client.id))
                .then((response) => {
                    this.sprints = response.data
                });
            axios.get(this.$route('clients.timeEntries', this.client.id))
                .then((response) => {
                    this.timeEntries = response.data
                });
        },
        type(slug, key, def) {
            const type = find(this.types, { slug })
            return get(type, key, def)
        },
        sprintUpdated () {
            this.$inertia.reload({
                only: ['sprints']
            })
        },
        async doMerge () {
            if (confirm("Are you sure you want to merge these clients?")) {
                this.merging = true
                await axios.get(
                    this.$route("clients.merge", {
                        from: this.client.id,
                        into: this.clientToMergeWith
                    })
                )
                this.$inertia.visit(this.$route("clients.show", this.clientToMergeWith))
                this.merging = false
            }
        },
        async archive() {
            if (confirm("Are you sure you want to archive this client?")) {
                this.archiving = true
                await this.$inertia.delete(
                    this.$route("clients.destroy", this.client.id)
                )
                this.archiving = false
            }
        },
        userSaved() {
            this.newUser = false
            this.$inertia.reload({
                only: ['users']
            })
        },
        startAddingNewUser() {
            this.newUser = {
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                client_id: this.client.id,
                is_active: true,
                type: 'client',
                permissions: ['activity']
            }
        },
        async addClientNote() {
            const { data } = await axios.post(this.$route("clients.note", this.client.id), {
                note: this.newNote.note,
                project_id: this.newNote.project_id
            })

            this.notes.push(data)
            this.newNote.note = ""
        },
        uploadFiles(files = null) {
            const promises = (Array.isArray(files) ? files : Array.from(this.$refs.file.files)).map((file) => {
                // upload file from $refs.file
                const formData = new FormData();
                formData.append('file', file);
                // update to media.update using axios
                return axios.post(this.$route('media.upload'), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            });

            Promise.all(promises).then((responses) => {
                // clear the file input
                this.$refs.file.value = null;
                responses.forEach(({ data }) => {
                    this.media.push(data);
                    axios.post(this.$route('clients.media.add', { client: this.client.id }), {
                        media_id: data.id
                    })
                })
            })
        },
    },
    watch: {
    }
}
</script>

<style lang="postcss" scoped>
.new-note :deep(div.tiptap) {
    padding-top: 6px;
    padding-bottom: 6px;
}
</style>
