import md5 from 'blueimp-md5';

export default {
    props: {
        usersById: Object,
    },
    methods: {
        urlencode (str) { // https://locutus.io/php/url/urlencode/
            str = (str + '')
            return encodeURIComponent(str)
                .replace(/!/g, '%21')
                .replace(/'/g, '%27')
                .replace(/\(/g, '%28')
                .replace(/\)/g, '%29')
                .replace(/\*/g, '%2A')
                .replace(/~/g, '%7E')
                .replace(/%20/g, '+')
        },
        gravatar(email, def) {
            return `https://www.gravatar.com/avatar/${md5(email.toLowerCase().trim())}?d=${this.urlencode(def)}`;
        },
        userAvatar(_user) {
            let user = _user
            if (user && typeof user === 'object' && !Array.isArray(user)) {
                if ('id' in user && user.id in this.usersById) {
                    user = this.usersById[user.id];
                }
                if ('photo' in user && user.photo) {
                    return user.photo;
                }
            }

            const initials = this.userInitials(user);
            const def = `https://ui-avatars.com/api/${initials}/128/0D8ABC/fff`

            if (this.userEmail(user)) {
                return this.gravatar(this.userEmail(user), def);
            }

            return def;
        },
        userInitials(user) {
            const name = this.userName(user);
            if (name) {
                const initials = name.match(/\b\w/g) || [];
                return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
            }
            return "UK"
        },
        userDescription(user) {
            const name = this.userName(user);
            const email = this.userEmail(user);
            if (name && email) {
                return `${name} <${email}>`;
            }
            if (name) {
                return name;
            }
            if (email) {
                return email;
            }
            if (this.auth && this.auth.user && this.auth.user.type === 'client') {
                return 'Eversite Team';
            }
            return "Unknown User";
        },
        userName(user) {
            if (user && typeof user === 'object' && !Array.isArray(user)) {
                if ('id' in user && user.id in this.usersById) {
                    return this.usersById[user.id].name;
                }
                if ('name' in user) {
                    return user.name;
                }
                if ('email' in user) {
                    // find the user by email
                    const found = Object.values(this.usersById).find(u => u.email === user.email);
                    if (found) {
                        return found.name;
                    }
                }
            }
            if (user in this.usersById) {
                return this.usersById[user].name;
            }
            if (this.auth && this.auth.user && this.auth.user.type === 'client') {
                return 'Eversite Team';
            }
            return 'Unknown User';
        },
        userEmail(user) {
            if (user && typeof user === 'object') {
                if ('id' in user && user.id in this.usersById) {
                    return this.usersById[user.id].email;
                }
                return user.email;
            }
            if (user in this.usersById) {
                return this.usersById[user].email;
            }
        },
        teamName(team) {
            if (this.teamsById && team in this.teamsById) {
                return this.teamsById[team].name;
            }
            return team;
        }
    }
}