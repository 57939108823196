<template>
    <div class="relative">
        <button
            ref="button"
            @click="toggle"
            class="text-gray-500 hover:text-gray-700 w-10 -mr-2"
        >
            <i class="fas fa-ellipsis-v"></i>
        </button>
        <Teleport to="body">
            <div
                v-if="isOpen"
                class="fixed z-50 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                :style="dropdownStyle"
                @click.stop
            >
                <div class="py-1">
                    <div class="px-4 py-2 text-sm text-gray-700 border-b border-gray-100">
                        Column Settings
                    </div>
                    <div class="max-h-96 overflow-y-auto">
                        <div
                            v-for="header in headers"
                            :key="header.key"
                            class="px-4 py-2 hover:bg-gray-50 flex items-center"
                        >
                            <input
                                type="checkbox"
                                :id="header.key"
                                v-model="localVisibleColumns"
                                :value="header.key"
                                class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            >
                            <label :for="header.key" class="ml-2 text-sm text-gray-700">
                                {{ header.label }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    name: 'ColumnSettingsDropdown',
    props: {
        cookieName: {
            type: String,
            required: true
        },
        headers: {
            type: Array,
            required: true
        },
        visibleColumns: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isOpen: false,
            localVisibleColumns: [...this.visibleColumns],
            dropdownPosition: { top: 0, left: 0 },
            isInternalUpdate: false
        }
    },
    computed: {
        dropdownStyle() {
            return {
                top: `${this.dropdownPosition.top}px`,
                left: `${this.dropdownPosition.left}px`
            }
        }
    },
    methods: {
        toggle() {
            if (!this.isOpen) {
                // Calculate position before opening
                const button = this.$refs.button
                const rect = button.getBoundingClientRect()
                this.dropdownPosition = {
                    top: rect.bottom + window.scrollY + 8,
                    left: rect.right - 224 // 224px is the width of the dropdown (w-56 = 14rem = 224px)
                }
            }
            this.isOpen = !this.isOpen
        },
        close() {
            this.isOpen = false
        },
        storeColumns(columns) {
            try {
                Cookies.set(this.cookieName, JSON.stringify(columns), { expires: 365 })
            } catch (e) {
                console.error('Error storing column settings in cookie:', e)
            }
        },
        updatePosition() {
            if (this.isOpen) {
                const button = this.$refs.button
                const rect = button.getBoundingClientRect()
                this.dropdownPosition = {
                    top: rect.bottom + window.scrollY + 8,
                    left: rect.right - 224
                }
            }
        }
    },
    watch: {
        visibleColumns: {
            handler(newValue) {
                if (!this.isInternalUpdate) {
                    this.localVisibleColumns = [...newValue]
                }
            },
            immediate: true
        },
        localVisibleColumns: {
            handler(newValue) {
                this.isInternalUpdate = true
                this.$emit('update:visibleColumns', newValue)
                this.storeColumns(newValue)
                this.$nextTick(() => {
                    this.isInternalUpdate = false
                })
            },
            deep: true
        }
    },
    mounted() {
        // Close dropdown when clicking outside
        document.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)) {
                this.close()
            }
        })
        // Update position on scroll and resize
        window.addEventListener('scroll', this.updatePosition)
        window.addEventListener('resize', this.updatePosition)
    },
    beforeUnmount() {
        // Clean up event listeners
        document.removeEventListener('click', this.close)
        window.removeEventListener('scroll', this.updatePosition)
        window.removeEventListener('resize', this.updatePosition)
    }
}
</script>
