<template>
    <div class="shadow-lg bg-gray-50 p-2 relative rounded border overflow-auto max-h-[350px] text-sm">
      <template v-if="items.length">
        <button
          class="block m-0 w-full text-left px-2 py-1 border border-transparent"
          :class="{
            'rounded bg-blue-100 text-blue-500': index === selectedIndex
          }"
          v-for="(item, index) in items"
          :key="index"
          @click="selectItem(index)"
        >
          {{ item in mentionables ? mentionables[item] : item }}
        </button>
      </template>
      <div class="item" v-else>
        No result
      </div>
    </div>
  </template>

<script>
  export default {
    props: {
      mentionables: {
        type: Object,
        required: true,
      },

      items: {
        type: Array,
        required: true,
      },

      command: {
        type: Function,
        required: true,
      },
    },

    data() {
      return {
        selectedIndex: 0,
      }
    },

    watch: {
      items() {
        this.selectedIndex = 0
      },
    },

    methods: {
      onKeyDown({ event }) {
        if (event.key === 'ArrowUp') {
          this.upHandler()
          return true
        }

        if (event.key === 'ArrowDown') {
          this.downHandler()
          return true
        }

        if (event.key === 'Enter') {
          this.enterHandler()
          return true
        }

        return false
      },

      upHandler() {
        this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
      },

      downHandler() {
        this.selectedIndex = (this.selectedIndex + 1) % this.items.length
      },

      enterHandler() {
        this.selectItem(this.selectedIndex)
      },

      selectItem(index) {
        const item = this.items[index]

        if (item) {
          this.command({ id: item })
        }
      },
    },
  }
</script>