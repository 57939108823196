<template>
    <Layout :auth='auth'>
        <Head title="Audit Log" />
        <template v-slot:title>
            <i class="fal user-cowboy"></i>
            Audit Log
        </template>
        <template v-slot:actions>
            <hgselect v-model="query" :options="options" class="max-w-sm">
                <template v-slot:value-prefix>
                    <span class="font-medium mr-1">
                        Show logs from:
                    </span>
                </template>
            </hgselect>
        </template>
        <div>
            <div class="mb-6">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6">
                    <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                        Date
                                    </th>
                                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                        Subject
                                    </th>
                                    <th class="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                        Action
                                    </th>
                                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                        Who
                                    </th>
                                    <th class="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                        Details
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                            <template v-for="(l, idx) in log.data" :key="l.id">
                                <tr
                                    class="hover:bg-gray-100 cursor-pointer"
                                    :class="{
                                        'bg-gray-50': idx % 2 == 1
                                    }"
                                >
                                    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                                        {{ datetime(l.created_at) }}
                                    </td>
                                    <td class="px-6 py-3 text-left whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                                        <Link
                                            v-if='l.object_type == "sprint"'
                                            :href='"/sprints/" + l.object_id'
                                            class="font-medium text-gray-500 hover:underline"
                                        >
                                            {{ l.object_description }}
                                        </Link>
                                        <Link
                                            v-if='l.object_type == "time_entry"'
                                            :href='"/?week=" + l.object_week + "&id=" + l.object_id'
                                            class="font-medium text-gray-500 hover:underline w-96 block truncate"
                                        >
                                            {{ l.object_description }}
                                        </Link>
                                        <div class="font-medium text-gray-500 hover:underline flex items-center" v-if='l.object_type == "email"' :title="l.data.subject">
                                            Sent email <div class="max-w-[250px] truncate text-gray-600 inline-block px-1">{{l.data.subject}}</div> to: <span class="text-blue-400">{{ l.data.to.join(', ') }}</span>
                                            <template v-if="l.data.cc && l.data.cc.length > 0">, cc: <span class="text-blue-400">{{ l.data.cc.join(', ') }}</span></template>
                                            <template v-if="l.data.bcc && l.data.bcc.length > 0">, bcc: <span class="text-blue-400">{{ l.data.bcc.join(', ') }}</span></template>
                                        </div>
                                    </td>
                                    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900 text-center">
                                        {{ ucfirst(l.action) }}
                                    </td>
                                    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                                        <span v-if='l.user_id'>
                                            {{ users[l.user_id] || '???' }}
                                        </span>
                                        <span v-else>System</span>
                                    </td>
                                    <td class="px-6 py-3 whitespace-nowrap text-sm text-right leading-5 text-gray-900">
                                        <a
                                            href="#"
                                            v-if='l.object_type == "time_entry"'
                                            @click.stop.prevent="showHistory(l.object_id)"
                                            class="font-medium text-blue-500 hover:underline"
                                        >
                                            Details <i class="fal fa-angle-right"></i>
                                        </a>
                                        <a
                                            href="#"
                                            v-else-if='l.data'
                                            @click.stop.prevent="showDetails(l.data)"
                                            class="font-medium text-blue-500 hover:underline"
                                        >
                                            Details <i class="fal fa-angle-right"></i>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- flex items-center justify-between -->
                <!-- flex justify-between flex-1 sm:hidden -->
                <!-- hidden sm:flex-1 sm:flex sm:items-center sm:justify-between -->
                <div v-html='pagination' class="mt-4"></div>
            </div>
        </div>
        <modal :show="!!showHistoryForEntry" @close='showHistoryForEntry = false'>
            <entry-history
                v-if="showHistoryForEntry"
                :sprintName="sprintName"
                :clientName="clientName"
                :entry="showHistoryForEntry" />
        </modal>
        <modal :show="!!showData" @close='showData = false'>
            <ul v-if="showData">
                <li v-for="(value, key) in showData" :key="key">
                    <span class="font-bold">{{ translateLogKey(key) }}:</span>
                    <span v-html="displayLogValue(key, value)"></span>
                </li>
            </ul>
        </modal>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import money from "../../Common/money.vue"
import modal from "../../Common/modal.vue"
import inp from "../../Common/inp.vue"
import btn from "../../Common/btn.vue"
import hgselect from "../../Common/hgselect.vue"
import moment from "moment"
import {capitalize, get} from "lodash"
import entryHistory from "../../Common/entryHistory.vue"
import { Head, Link } from '@inertiajs/vue3'

export default {
    name: "Audit Log",
    props: {
        only: String,
        auth: Object,
        users: Object,
        clients: Object,
        sprints: Object,
        log: [Object, Array],
        pagination: String
    },
    components: { btn, Head, Layout, money, modal, inp, hgselect, entryHistory, Link },
    data() {
        return {
            showHistoryForEntry: null,
            showData: null,
            query: this.only || 'all'
        }
    },
    computed: {
        options() {
            let opts = {
                all: 'All Logs',
            }

            opts = {
                ...opts,
                '--': '--',
                'time_entry': 'Time Entry Logs',
                'sprint': 'Sprint Logs',
                'biller_genie': 'Biller Genie Logs',
                'email': 'Email Logs',
                '---': '---',
                system: 'System',
                mine: 'My Entries'
            }

            return opts
        }
    },
    methods: {
        showDetails(data) {
            this.showData = data
        },
        showHistory(entry_id) {
            this.showHistoryForEntry = {
                id: entry_id
            }
        },
        clientName(entry) {
            return get(this.clients, entry.client_id, '--')
        },
        sprintName(entry) {
            return get(this.sprints, entry.sprint_id, '--')
        },
        displayLogValue(key, value) {
            if (Array.isArray(value) && value.length == 2) {
                return this.displayLogValue(key, value[0]) + ' &rarr; ' + this.displayLogValue(key, value[1])
            }
            switch(key) {
                case 'due_date':
                case 'dueDate':
                case 'date':
                    return this.datetime(value)
                case 'actual':
                case 'planned':
                    return this.number(value)
                case 'rate':
                case 'billableNow':
                case 'billableGoal':
                    return this.money(value)
                case 'sprint_id':
                    return this.sprintName({sprint_id: value})
                case 'client_id':
                    return this.clientName({client_id: value})
            }
            return value
        },
        translateLogKey(key) {
            switch(key) {
                case 'sprint_id':
                    return 'Sprint'
                case 'client_id':
                    return 'Client'
            }
            key = String(key).replace(/([A-Z])/g, " $1")
            key = key.replace("_", " ")
            return key[0].toUpperCase() + key.slice(1)
        },
        datetime(dt) {
            let date = moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local()
            if (date.format('YYYY') == moment.utc().local().format('YYYY')) {
                return date.format('MMM Do, h:mma')
            }
            return date.format('MMM Do YYYY, h:mma')
        },
        ucfirst(str) {
            return capitalize(str)
        },
        money(num) {
            return (
                "$" +
                Number(num).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                })
            )
        },
        number(num) {
            return Number(num).toLocaleString(undefined, {
                minimumFractionDigits: 2
            })
        }
    },
    watch: {
        query () {
            this.$inertia.visit('/audit', {
                data: { only: this.query }
            })
        }
    }
}
</script>

<style lang="postcss" scoped></style>
