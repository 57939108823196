<template>
    <div class="relative">
        <div class="flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th v-for="(header, idx) in visibleHeaders"
                                        :key="header.key"
                                        @click="order(header.key)"
                                        scope="col"
                                        :class="[
                                            header.class || 'px-3 py-3.5 text-left text-xs font-semibold text-gray-900 whitespace-nowrap cursor-pointer',
                                            header.specialClass
                                        ]"
                                    >
                                        <div class="flex items-center">
                                            <label class="mr-1">{{ header.label }}</label>
                                            <span v-show="orderBy[0] === header.key && orderBy[1] === 'asc'"><i class="fas fa-caret-up"></i></span>
                                            <span v-show="orderBy[0] === header.key && orderBy[1] === 'desc'"><i class="fas fa-caret-down"></i></span>
                                            <div
                                                v-if="idx === visibleHeaders.length - 1"
                                                @click.stop
                                                class="ml-auto"
                                            >
                                                <ColumnSettingsDropdown
                                                    :cookieName="cookieName"
                                                    :headers="headers"
                                                    v-model:visibleColumns="visibleColumns"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <MemoRow
                                    v-for="memo in memos"
                                    :key="memo.id"
                                    :memo="memo"
                                    :usersById="usersById"
                                    :teamsById="teamsById"
                                    :isTeamMember="isTeamMember"
                                    :selectable="selectable"
                                    :selected="selected.findIndex(m => m.id === memo.id) > -1"
                                    :visibleColumns="visibleColumns"
                                    @filter="(filter) => $emit('filter', filter)"
                                    @select="($event) => $emit('select', $event, memo)"
                                    @deselect="($event) => $emit('deselect', memo)"
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MemoRow from './memoRow.vue'
import ColumnSettingsDropdown from './ColumnSettingsDropdown.vue'
import Cookies from 'js-cookie'

const DEFAULT_HEADERS = [
    { key: 'ticket_number', label: 'Number' },
    { key: 'status', label: 'Status'},
    { key: 'priority', label: 'Priority' },
    { key: 'category', label: 'Category' },
    { key: 'subject', label: 'Subject' },
    { key: 'client', label: 'Client' },
    { key: 'assigned', label: 'Assigned' },
    { key: 'team', label: 'Team' },
    { key: 'created_at', label: 'Created At' },
    { key: 'last_activity_at', label: 'Last Activity' }
]

const DEFAULT_CLIENT_HEADERS = [
    { key: 'ticket_number', label: 'Number' },
    { key: 'status', label: 'Status'},
    { key: 'subject', label: 'Subject' },
    { key: 'created_at', label: 'Created At' },
    { key: 'last_activity_at', label: 'Last Activity' }
]

// Default columns for small screens
const DEFAULT_SMALL_COLUMNS = [
    'ticket_number',
    'status',
    'subject',
    'last_activity_at'
]

const DEFAULT_CLIENT_SMALL_COLUMNS = [
    'ticket_number',
    'status',
    'subject',
    'last_activity_at'
]

export default {
    name: 'MemoTable',
    components: {
        MemoRow,
        ColumnSettingsDropdown
    },
    props: {
        memos: {
            type: Array,
            required: true
        },
        usersById: {
            type: Object,
            required: true
        },
        teamsById: {
            type: Object,
            required: true
        },
        isTeamMember: {
            type: Boolean,
            required: true
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Array,
            default: () => []
        },
        orderBy: {
            type: Array,
            default: () => ['last_activity_at', 'desc']
        }
    },
    data() {
        return {
            headers: this.isTeamMember ? DEFAULT_HEADERS : DEFAULT_CLIENT_HEADERS,
            visibleColumns: [],
            showColumnSettings: false,
            screenIsSmall: false,
            ignoreVisibleColumnsWatch: false, // Flag to prevent circular saving
            resizeTimeout: null
        }
    },
    computed: {
        visibleHeaders() {
            return this.headers.filter(header => this.visibleColumns.includes(header.key))
        },
        cookieName() {
            return this.screenIsSmall ? 'column-settings-sm' : 'column-settings'
        }
    },
    watch: {
        visibleColumns: {
            handler(newColumns) {
                // Only save if not in the middle of updating from resize
                if (!this.ignoreVisibleColumnsWatch) {
                    this.saveColumnSettings(newColumns);
                }
            },
            deep: true
        }
    },
    methods: {
        order(key) {
            if (this.orderBy[0] === key) {
                if (this.orderBy[1] === 'asc') {
                    this.$emit('update:orderBy', [key, 'desc'])
                } else {
                    this.$emit('update:orderBy', [null, null])
                }
            } else {
                this.$emit('update:orderBy', [key, 'asc'])
            }
        },
        getStoredColumns() {
            const headers = this.isTeamMember ? DEFAULT_HEADERS : DEFAULT_CLIENT_HEADERS
            const defaultSmallColumns = this.isTeamMember ? DEFAULT_SMALL_COLUMNS : DEFAULT_CLIENT_SMALL_COLUMNS

            try {
                const stored = Cookies.get(this.cookieName)
                if (stored) {
                    const parsed = JSON.parse(stored)
                    // Validate that all stored columns exist in headers
                    const validColumns = parsed.filter(col => headers.some(h => h.key === col))
                    // If no valid columns were found, use defaults based on screen size
                    return validColumns.length > 0 ? validColumns : (
                        this.screenIsSmall ? defaultSmallColumns : headers.map(h => h.key)
                    )
                }
            } catch (e) {
                console.error(`Error reading column settings from cookie (${this.cookieName}):`, e)
            }

            // Initialize with appropriate defaults if no cookie settings or error
            return this.screenIsSmall ? defaultSmallColumns : headers.map(h => h.key)
        },
        saveColumnSettings(columns) {
            try {
                Cookies.set(this.cookieName, JSON.stringify(columns), { expires: 365 })
            } catch (e) {
                console.error(`Error saving column settings to cookie (${this.cookieName}):`, e)
            }
        },
        handleResize() {
            // Only check every 100ms to avoid excessive calculations
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }

            this.resizeTimeout = setTimeout(() => {
                const wasSmall = this.screenIsSmall;
                this.screenIsSmall = window.innerWidth < 768; // MD breakpoint in Tailwind

                // Only update if the screen size category has changed
                if (wasSmall !== this.screenIsSmall) {
                    // Prevent watcher from saving while we're updating
                    this.ignoreVisibleColumnsWatch = true;

                    // Force reload of column settings based on new screen size
                    const newColumns = this.getStoredColumns();

                    // Update columns and reset the flag
                    this.visibleColumns = newColumns;

                    // Reset flag after update completes
                    this.$nextTick(() => {
                        this.ignoreVisibleColumnsWatch = false;
                    });
                }
            }, 100);
        }
    },
    created() {
        // Determine initial screen size
        this.screenIsSmall = window.innerWidth < 768;

        // Initialize visible columns from cookie or default
        this.visibleColumns = this.getStoredColumns();
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>
