<template>
    <div
        class="flex flex-wrap gap-1 items-center bg-white p-2 border border-gray-300 rounded-md text-sm"
    >
        <span class="font-bold" v-if="showPrefix">To:</span>
        <template v-for="watcher in watchers.filter(watcher => watcher.id != auth.user.id)" :key="watcher.id">
            <a
                href="#"
                @click.prevent="unwatch(watcher.id)"
                class="ml-1 whitespace-nowrap group hover:text-red-700 hover:underline cursor-pointer relative"
            >
                {{ userName(watcher) }} &lt;{{ userEmail(watcher) }}&gt;<span class="group-[:nth-last-child(2)]:hidden">,</span>
                <i class="text-[8px] fa fa-times absolute top-1/2 -translate-y-1/2 left-[-0.45rem] text-red-700 hidden group-hover:inline-block"></i>
            </a>
        </template>
        <div class="flex items-center gap-1 ml-1" v-if="newWatcher.adding">
            <input
                class="py-0 px-1 bg-transparent !rounded-none !border-x-0 !border-t-0 placeholder:text-gray-300 w-auto leading-none"
                type="email"
                v-model="newWatcher.email"
                placeholder="joe@email.net"
                autofocus
                @keyup.enter="add"
                @keyup.esc="newWatcher.adding = false"
                @blur="add"
            />
            <a href="#" class="text-green-600 ml-1" @click="add">
                <i class="fad fa-plus-square"></i>
            </a>
            <a href="#" class="text-gray-800 hover:text-red-600 ml-1" @click="newWatcher.adding = false">
                <i class="fad fa-times-square"></i>
            </a>
        </div>
        <template v-else>
            <a href="#" class="text-gray-800 hover:text-green-600 ml-1" @click="newWatcher.adding = true">
                <i class="fad fa-plus-square"></i>
            </a>
        </template>
    </div>
</template>

<script setup>
import { reactive } from 'vue'
const $props = defineProps({
    auth: Object,
    watchers: Array,
    userName: Function,
    userEmail: Function,
    addWatcher: Function,
    unwatch: Function,
    showPrefix: {
        type: Boolean,
        default: true
    }
})

const newWatcher = reactive({
    adding: false,
    email: ''
})

async function add() {
    if (!newWatcher.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        return;
    }

    await $props.addWatcher(newWatcher.email)
    newWatcher.email = ''
    newWatcher.adding = false
}
</script>
