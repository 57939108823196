<template>
    <Head title="Login" />
    <div
        class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
        <div class="sm:mx-auto sm:w-full sm:max-w-md flex justify-center">
            <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900 flex gap-2">
                <img class="w-8 object-contain" :src="asset('/images/logo-mark.svg')" />
                <span>{{ welcomeMessage }}</span>
            </h2>
        </div>

        <div class="sm:mx-auto sm:w-full sm:max-w-md mt-3" v-if="messages.length">
            <template v-for="(msg, idx) in messages" :key="'msg-' + idx">
                <div :class="'bg-'+msg.color+'-50 border-l-4 border-'+msg.color+'-400 p-4'">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg :class="'h-5 w-5 text-'+msg.color+'-400'" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <p :class="'text-sm leading-5 text-'+msg.color+'-700'">
                                {{ msg.message }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div
                class="py-8 px-4 shadow sm:rounded-lg sm:px-10"
                :class="{
                    'bg-white': state !== STATE_TOKEN,
                    'bg-green-50': state === STATE_TOKEN
                }"
            >
                <div v-if="state === STATE_TOKEN">
                    <div class="flex items-center">
                        <div class="flex-shrink-0">
                            <svg :class="'h-5 w-5 text-green-600'" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <p :class="'text-sm leading-5 text-green-800'">
                                A login link has been sent to your email address. Please check your inbox.
                            </p>
                        </div>
                    </div>
                </div>
                <form v-else action="#" @submit.prevent="login">
                    <div>
                        <label
                            for="email"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Email address
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                id="email"
                                type="email"
                                v-model="email"
                                required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                    </div>

                    <div class="mt-6" v-if="state === STATE_PASSWORD">
                        <label
                            for="password"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Password
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                id="password"
                                type="password"
                                v-model="password"
                                required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                autofocus
                            />
                        </div>
                    </div>

                    <div class="mt-6 flex items-center justify-between">
                        <div class="flex items-center">
                            <input
                                id="remember_me"
                                v-model="remember"
                                type="checkbox"
                                class="h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                            />
                            <label
                                for="remember_me"
                                class="ml-2 block text-sm leading-5 text-gray-900"
                            >
                                Remember me
                            </label>
                        </div>

                        <div class="text-sm leading-5" v-if="state === STATE_PASSWORD">
                            <a
                                href="/password/reset"
                                class="font-medium text-gray-800 hover:text-gray-600 focus:outline-none focus:underline transition ease-in-out duration-150"
                            >
                                Forgot your password?
                            </a>
                        </div>
                    </div>

                    <div class="mt-6">
                        <span class="block w-full rounded-md shadow-sm">
                            <button
                                type="submit"
                                class="w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out"
                            >
                                <span
                                    class="inline-block"
                                    :class="{
                                        'opacity-0 absolute': !loading,
                                        'opacity-1 mr-2': loading
                                    }"
                                >
                                    <i class="fa fa-spinner-third fa-spin"></i>
                                </span>
                                <template v-if="state === STATE_EMAIL">
                                    Next
                                </template>
                                <template v-if="state === STATE_PASSWORD">
                                    Sign in
                                </template>
                            </button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import axios from 'axios'

const STATE_EMAIL = 1;
const STATE_PASSWORD = 2;
const STATE_TOKEN = 3;

export default {
    components: { Head },
    props: {
        errors: Object,
        flash: Object
    },
    data() {
        return {
            STATE_EMAIL,
            STATE_PASSWORD,
            STATE_TOKEN,

            state: STATE_EMAIL,
            loading: false,
            email: "",
            password: null,
            remember: false,
            localErrors: null
        }
    },
    beforeUnmount() {
        // After login, the csrf token is no longer valid
        // and I can't figure out how to refresh it well -
        // so let's just reload the page for now.
        window.location = '/';
    },
    computed: {
        allErrors() {
            return {
                ...this.errors,
                ...this.localErrors
            }
        },
        messages() {
            const alerts = []
            if (this.allErrors) {
               Object.keys(this.allErrors).forEach((key) => {
                   this.allErrors[key].forEach((message) => {
                        alerts.push({
                            color: 'red',
                            message
                        })
                   })
                })
            }
            if (this.flash && this.flash.status) {
                alerts.push({
                    color: 'green',
                    message: this.flash.status
                })
            }
            return alerts
        },
        welcomeMessage() {
            const hours = (new Date).getHours()
            if (hours < 12) {
                return "Good Morning! ☕"
            } else if (hours < 15) {
                return "Good Afternoon! 🌞"
            } else if (hours > 20) {
                return "Burning the midnight oil! 🌚"
            } else {
                return "Good Evening! 🌆"
            }
        }
    },
    methods: {
        async login() {
            this.localErrors = null
            this.loading = true
            if (this.state === STATE_EMAIL) {
                try {
                    const response = await axios.post(this.$route("login"), {
                        email: this.email,
                        password: this.password,
                        remember: this.remember
                    })

                    const data = response.data
                    if (data.passwordRequired) {
                        this.state = STATE_PASSWORD;
                    }
                    if (data.loginTokenSent) {
                        this.state = STATE_TOKEN;
                    }
                } catch (error) {
                    if (error.response.status === 422) {
                        this.localErrors = error.response.data.errors
                    } else {
                        console.error(error)
                    }
                }
            } else if (this.state === STATE_PASSWORD) {
                this.$inertia.post(this.$route("login"), {
                    email: this.email,
                    password: this.password,
                    remember: this.remember
                })
            }
            this.loading = false
        }
    }
}
</script>

<style lang="postcss" scoped></style>
