<template>
    <Layout :auth='auth'>
        <Head title="Activity" />
        <template v-slot:title>
            Activity
            <ul class="flex items-center flex-wrap gap-3 mt-4">
                <li>
                    <btn
                        @click="activeTicket = null"
                        white
                        :outline="activeTicket !== null"
                    >
                        <div class="flex flex-col items-start max-w-xs truncate">
                            <span>Ticket List</span>
                            <span class="text-xs">{{ memos.total }} Tickets</span>
                        </div>
                    </btn>
                </li>
                <template v-for="ticket in openTickets" :key="ticket">
                    <li>
                        <btn
                            white
                            @click="activeTicket = ticket"
                            :outline="activeTicket !== ticket"
                        >
                            <div class="flex items-center gap-2">
                                <div class="flex flex-col items-start max-w-xs truncate">
                                    <span>{{ ticketSubject(ticket) }}</span>
                                    <span v-if="ticket == 'new'" class="text-xs">New Ticket</span>
                                    <span v-else class="text-xs">#{{ ticket }}</span>
                                </div>
                                <a
                                    v-if="ticketData[ticket]"
                                    href="#"
                                    @click.prevent="closeTicket(ticket)"
                                    class="text-gray-400 hover:text-gray-600 hover:bg-gray-200 h-6 w-6 rounded-full flex items-center justify-center translate-x-2"
                                >
                                    <i class="fal fa-times"></i>
                                </a>
                                <div v-else>
                                    <i class="fal fa-spinner-third fa-spin"></i>
                                </div>
                            </div>
                        </btn>
                    </li>
                </template>
                <li>
                    <btn
                        @click="select(null, {ticket_number: 'new'})"
                        v-if="isTeamMember"
                        white
                        outline
                    >
                        <i class="fal fa-plus py-3 px-2"></i>
                    </btn>
                </li>
                <li>
                    <btn
                        @click="searchOpen = !searchOpen"
                        v-if="isTeamMember"
                        white
                        outline
                    >
                        <i class="fal fa-search py-3 px-2"></i>
                        <input
                            ref="searchInput"
                            v-if="searchOpen"
                            type="text"
                            pattern="[0-9]*"
                            v-model="ticketSearch"
                            @keydown.enter="loadSearchedTicket()"
                            @click.stop
                            class="bg-transparent focus:ring-0 max-w-[100px] px-0 text-sm placeholder:text-sm"
                            style="border: none;"
                            placeholder="Ticket Number"
                            autofocus
                        />
                    </btn>
                </li>
            </ul>
        </template>
        <template v-slot:actions>
        </template>
        <template v-if="activeTicket && ticketData[activeTicket] && ticketData[activeTicket].memo">
            <MemoContent
                :key="activeTicket"
                :memo="ticketData[activeTicket].memo"
                :macros="macros"
                :auth="auth"
                :relatedMemos="ticketData[activeTicket].relatedMemos"
                :activities="ticketData[activeTicket].activities"
                :clientsById="clientsById"
                :usersById="usersById"
                :teamsById="teamsById"
                :watching="false"
                :actions="ticketData[activeTicket].actions"
                :checklists="ticketData[activeTicket].checklists"
            />
        </template>
        <template v-else>
            <div class="w-full mb-4" v-if="isTeamMember && false">
                <div class="flex items-center max-w-full">
                    <ul class="flex flex-col">
                        <li @click="showingChart = 'status'" class="inline-block p-2 rounded-tl-lg rounded-tr-lg cursor-pointer" :class="{'bg-white': showingChart === 'status', 'bg-gray-100': showingChart !== 'status'}">
                            <i class="fa fa-fw fa-arrow-progress"></i>
                        </li>
                        <li @click="showingChart = 'priority'" class="inline-block p-2 rounded-bl-lg rounded-br-lg cursor-pointer" :class="{'bg-white': showingChart === 'priority', 'bg-gray-100': showingChart !== 'priority'}">
                            <i class="fa fa-fw fa-traffic-light"></i>
                        </li>
                    </ul>
                    <div class="flex-grow aspect-[5] lg:aspect-[10]">
                        <LineChart :data="statChart.data" :options="statChart.options" />
                    </div>
                    <div class="px-6 text-center">
                        <div class="text-sm text-gray-400 uppercase">Unresolved</div>
                        <div
                            class="text-4xl font-black cursor-pointer hover:underline"
                            @click="setFilter({
                                status: ['new', 'in-progress', 'need-more-information', 'waiting-on-client', 'reopened']
                            }, true)"
                        >{{  stats.current.unresolved }}</div>
                        <div class="mt-2 text-sm text-gray-400 uppercase">Important</div>
                        <div
                            class="text-4xl font-black cursor-pointer hover:underline"
                            @click="setFilter({
                                status: ['new', 'in-progress', 'need-more-information', 'waiting-on-client', 'reopened'],
                                priority: [4, 5]
                            }, true)"
                        >{{  stats.current.important }}</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap items-center gap-2 mb-2">
                <template v-if="filter.watching">
                    <span @click="filter.watching = false" class="group inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full hover:text-blue-700 hover:bg-blue-100 transition-colors duration-200 cursor-pointer">
                        <i class="far fa-eye"></i> Watching
                        <i class="fal fa-times ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></i>
                    </span>
                </template>
                <template v-if="filter.recent">
                    <span @click="filter.recent = false" class="group inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full hover:text-blue-700 hover:bg-blue-100 transition-colors duration-200 cursor-pointer">
                        <i class="far fa-clock"></i> Recently Updated
                        <i class="fal fa-times ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></i>
                    </span>
                </template>
                <template v-for="status in filter.status" :key="'status-' + status">
                    <span @click="toggleFilter('status', status)" class="group inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full hover:text-purple-700 hover:bg-purple-100 transition-colors duration-200 cursor-pointer">
                        Status: {{ options.statuses[status] }}
                        <i class="fal fa-times ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></i>
                    </span>
                </template>
                <template v-for="priority in filter.priority" :key="'priority-' + priority">
                    <span @click="toggleFilter('priority', priority)" class="group inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full hover:text-orange-700 hover:bg-orange-100 transition-colors duration-200 cursor-pointer">
                        Priority: {{ options.priorities[priority] }}
                        <i class="fal fa-times ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></i>
                    </span>
                </template>
                <template v-for="category in filter.category" :key="'category-' + category">
                    <span @click="toggleFilter('category', category)" class="group inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full hover:text-green-700 hover:bg-green-100 transition-colors duration-200 cursor-pointer">
                        Category: {{ options.categories[category] }}
                        <i class="fal fa-times ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></i>
                    </span>
                </template>
                <template v-if="filter.assignedTo">
                    <span @click="filter.assignedTo = null" class="group inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full hover:text-indigo-700 hover:bg-indigo-100 transition-colors duration-200 cursor-pointer">
                        <template v-if="filter.assignedTo == 'none'">Unassigned</template>
                        <template v-else>Assigned to: {{ usersById[filter.assignedTo]?.name }}</template>
                        <i class="fal fa-times ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></i>
                    </span>
                </template>
                <template v-if="filter.team">
                    <span @click="filter.team = null" class="group inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full hover:text-indigo-700 hover:bg-indigo-100 transition-colors duration-200 cursor-pointer">
                        Team: {{ teamsById[filter.team]?.name }}
                        <i class="fal fa-times ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></i>
                    </span>
                </template>
                <template v-if="filter.client">
                    <span @click="filter.client = null" class="group inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full hover:text-teal-700 hover:bg-teal-100 transition-colors duration-200 cursor-pointer">
                        Client: {{ clientsById[filter.client]?.name }}
                        <i class="fal fa-times ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></i>
                    </span>
                </template>
                <template v-if="filter.project">
                    <span @click="filter.project = null" class="group inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-full hover:text-teal-700 hover:bg-teal-100 transition-colors duration-200 cursor-pointer">
                        Project: {{ projects.find(p => p.id === filter.project)?.name }}
                        <i class="fal fa-times ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"></i>
                    </span>
                </template>
            </div>
            <div class="flex flex-col lg:flex-row items-stretch w-full gap-2">
                <div class="flex-1 w-full">
                    <editor
                        plain-text
                        :mentionables="searchMentions"
                        :icon="loading ? 'fal fa-spinner-third fa-spin' : 'fal fa-magnifying-glass'"
                        v-model="search"
                        placeholder="Search! Or type @user, @team or @client..."
                        single-line
                        @keydown.enter="searchTickets()"
                    />
                </div>
                <btn @click="select(null, {ticket_number: 'new'})" green small v-if="isTeamMember">
                    <i class="fal fa-plus mr-2"></i> New Ticket
                </btn>
                <btn :red="bulkSelect" :gray="!bulkSelect" v-if="isTeamMember && memos.total > 0" @click="bulkSelect = !bulkSelect" small>
                    <template v-if="bulkSelect">Cancel</template> Bulk Update
                </btn>
            </div>
            <template v-if="isTeamMember && memos.total > 0 && bulkSelect">
                <div class="flex flex-wrap items-end gap-2 p-4 mt-4 border border-blue-200 rounded-md bg-blue-50">
                    <div class="w-full">
                        Update <span class="font-semibold" v-html="selectedMemos.length"></span> Activit<span v-if="selectedMemos.length == 1">y</span><span v-else>ies</span>
                    </div>
                    <div class="flex-1">
                        <label for="status" class="block text-sm font-medium leading-5 text-gray-700">Status</label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <hgselect
                                v-model="bulkUpdate.status"
                                :options="options.statuses"
                            />
                        </div>
                    </div>
                    <div class="flex-1">
                        <label for="priority" class="block text-sm font-medium leading-5 text-gray-700">Priority</label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <hgselect
                                v-model="bulkUpdate.priority"
                                :options="options.priorities"
                            />
                        </div>
                    </div>
                    <div class="flex-1">
                        <label for="assigned_team" class="block text-sm font-medium leading-5 text-gray-700">Team</label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <hgselect
                                v-model="bulkUpdate.assigned_team"
                                :options="{
                                    '': 'Unassigned',
                                    ...options.teams
                                }"
                            />
                        </div>
                    </div>
                    <div class="flex-1">
                        <label for="assigned_to" class="block text-sm font-medium leading-5 text-gray-700">Assigned User</label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <hgselect
                                v-model="bulkUpdate.assigned_to"
                                :options="{
                                    '': 'Unassigned',
                                    ...options.activeTeamMembers
                                }"
                            />
                        </div>
                    </div>
                    <div>
                        <btn green @click="saveBulkChanges" :disabled="selectedMemos.length === 0" :loading="bulkSaving">Save Changes</btn>
                    </div>
                </div>
            </template>
            <div
                class="relative z-0 mt-4"
            >
                <MemoTable
                    :memos="memos.data"
                    :usersById="usersById"
                    :teamsById="teamsById"
                    :isTeamMember="isTeamMember"
                    @filter="(filter) => updateFilter(filter, true)"
                    :selectable="bulkSelect"
                    :selected="selectedMemos"
                    @select="($event, memo) => select($event, memo)"
                    @deselect="($event, memo) => deselect($event, memo)"
                    v-model:orderBy="orderBy"
                >
                </MemoTable>
            </div>
            <nav v-if="memos.total > 0" class="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6" aria-label="Pagination">
                <div class="hidden sm:block">
                    <p class="text-sm text-gray-700">
                    Showing
                    <span class="font-medium" v-html="memos.from"></span>
                    to
                    <span class="font-medium" v-html="memos.to"></span>
                    of
                    <span class="font-medium" v-html="memos.total"></span>
                    results
                    </p>
                </div>
                <div class="flex justify-between flex-1 sm:justify-end">
                    <a
                        href="#"
                        v-on:click.prevent="prevPage()"
                        class="relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        :class="{
                            'opacity-50 pointer-events-none': memos.from <= 1
                        }"
                    >Previous</a>
                    <a
                        href="#"
                        v-on:click.prevent="nextPage()"
                        class="relative inline-flex items-center px-3 py-2 ml-3 text-sm font-semibold text-gray-900 bg-white rounded-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        :class="{
                            'opacity-50 pointer-events-none': memos.to >= memos.total
                        }"
                    >Next</a>
                </div>
            </nav>
            <div class="py-4" v-if="memos.total === 0">
                <alert color="green">
                    No activity found that matches your search criteria.
                </alert>
            </div>
        </template>
        <template v-slot:left-of-container>
            <template v-if="!activeTicket || !ticketData[activeTicket]">
                <div class="relative w-full lg:w-64 px-5 py-6 mr-6 rounded-lg shadow-lg bg-gray-50 sm:px-6 mb-6">
                    <div class="lg:hidden font-medium cursor-pointer" @click="collapseFilters = !collapseFilters">
                        <i
                            class="fa fa-angle-down transition"
                            :class="{
                                'rotate-0': !collapseFilters,
                                '-rotate-90': collapseFilters
                            }"
                        ></i>
                        Activity Filters
                    </div>
                    <div class="flow-root lg:block" :class="!collapseFilters ? 'block' : 'hidden'">
                        <ul class="space-y-1 select-none">
                            <li class="px-2 py-1 mb-2" v-if="isTeamMember">
                                <label for="client" class="block text-sm font-medium leading-5 text-gray-700">Client</label>
                                <div class="relative mt-1 rounded-md shadow-sm">
                                    <hgselect
                                        v-model="filter.client"
                                        :options="options.clients"
                                        clearable
                                    />
                                </div>
                            </li>
                            <li class="px-2 py-1 mb-2" v-if="(filter.client || filter.project) && options.projects">
                                <label for="project" class="block text-sm font-medium leading-5 text-gray-700">Project</label>
                                <div class="relative mt-1 rounded-md shadow-sm">
                                    <hgselect
                                        v-model="filter.project"
                                        :options="options.projects"
                                    />
                                </div>
                            </li>
                            <li class="px-2 py-1 mb-2" v-if="isTeamMember">
                                <label for="assigned_to" class="block text-sm font-medium leading-5 text-gray-700">Assigned To</label>
                                <div class="relative mt-1 rounded-md shadow-sm">
                                    <hgselect
                                        v-model="assignee"
                                        :options="options.assignees"
                                        track-by="value"
                                        clearable
                                    />
                                </div>
                            </li>
                            <template v-for='(item, idx) in sidebar'>
                                <li
                                    :key="'seperator-' + idx"
                                    v-if="item.seperator"
                                    class="flex items-center px-2 py-1 capitalize cursor-pointer"
                                >
                                    <div class="relative w-full h-px mt-4 mb-2 text-xs uppercase bg-gray-400">
                                        <span class="absolute bottom-0 px-4 translate-y-1/2 bg-gray-50 left-2">
                                            {{ item.label }}
                                        </span>
                                        <span
                                            v-if="item.action"
                                            @click="item.action.handle()"
                                            class="absolute bottom-0 px-4 translate-y-1/2 bg-gray-50 right-2 text-gray-400 hover:text-green-600 cursor-pointer"
                                        >
                                            <i :class="item.action.icon"></i>
                                        </span>
                                    </div>
                                </li>
                                <li
                                    :key="'draggable-' + idx"
                                    v-else-if="item.draggable"
                                >
                                    <draggable
                                        v-model="item.children"
                                        tag="ul"
                                        item-key="id"
                                        @change="item.orderChanged"
                                    >
                                        <template #item="{element}">
                                            <li
                                                class="flex items-center px-2 py-1 capitalize rounded cursor-pointer relative"
                                                :class="{
                                                    'bg-gray-100': element.active()
                                                }"
                                                @click="element.click()"
                                            >
                                                <glow :green="element.active()" />
                                                <span class="truncate">{{ element.label }}</span>
                                                <span v-if="element.badge && element.badge()" class="ml-auto px-2 py-[2px] text-xs font-semibold text-white bg-blue-500 rounded-lg">
                                                {{ element.badge() }}
                                                </span>
                                                <span
                                                    v-if="element.action && element.active()"
                                                    @click="element.action.handle()"
                                                    class="cursor-pointer ml-auto text-gray-400 hover:text-gray-600"
                                                    :title="element.action.title"
                                                >
                                                    <i :class="element.action.icon"></i>
                                                </span>
                                            </li>
                                        </template>
                                    </draggable>
                                </li>
                                <li
                                    v-else
                                    :key="'item-' + idx"
                                    class="flex items-center px-2 py-1 capitalize rounded cursor-pointer relative"
                                    :class="{
                                        'bg-gray-100': item.active()
                                    }"
                                    @click="item.click()"
                                >
                                    <glow :green="item.active()" />
                                    <span class="truncate">{{ item.label }}</span>
                                    <span v-if="item.badge && item.badge()" class="ml-auto px-2 py-[2px] text-xs font-semibold text-white bg-blue-500 rounded-lg">
                                        {{ item.badge() }}
                                    </span>
                                    <span
                                        v-if="item.action && item.active()"
                                        @click="item.action.handle()"
                                        class="cursor-pointer ml-auto text-gray-400 hover:text-gray-600"
                                        :title="item.action.title"
                                    >
                                        <i :class="item.action.icon"></i>
                                    </span>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </template>
        </template>
    </Layout>
</template>

<script>
import alert from "../../Common/alert.vue"
import btn from "../../Common/btn.vue"
import avatar from "../../Common/avatar.vue"
import glow from "../../Common/glow.vue"
import inp from "../../Common/inp.vue"
import editor from "../../Common/editor.vue"
import modal from "../../Common/modal.vue"
import Layout from "../../Common/Layout.vue"
import auth from "../../Common/Mixins/auth"
import hgselect from "../../Common/hgselect.vue"
import MemoTable from "./Common/memoTable.vue"
import { debounce } from "lodash"
import { Head, router } from '@inertiajs/vue3'
import LineChart from "../../Charts/Line.vue"
import axios from "axios"
import moment from "moment"
import MemoContent from "./Memo/MemoContent.vue"
import { nextTick } from "vue"
import draggable from 'vuedraggable'

export default {
    name: 'ActivityIndex',
    mixins: [auth],
    components: {
        alert,
        btn,
        draggable,
        glow,
        Head,
        Layout,
        hgselect,
        inp,
        editor,
        modal,
        avatar,
        MemoTable,
        LineChart,
        MemoContent
    },
    props: {
        auth: Object,
        filters: Object,
        clients: Array,
        projects: Array,
        macros: Array,
        memos: Object,
        usersById: Object,
        teamsById: Object,
        stats: Object,
        savedFilters: Array,
        openTicket: String,
    },
    data() {
        return {
            searchOpen: false,
            ticketSearch: null,
            loading: false,
            activeTicket: this.openTicket,
            openTickets: this.openTicket ? [this.openTicket] : [],
            ticketData: {},
            showingChart: 'status',
            search: this.filters.search,
            collapseFilters: true,
            assignee: null,
            filter: {
                _counter: 0,
                ...this.filters,
            },
            bulkUpdate: {
                status: null,
                priority: null,
                assigned_team: null,
                assigned_to: null
            },
            bulkSaving: false,
            bulkSelect: false,
            selectedMemos: [],
            localSavedFilters: this.savedFilters ? [ ...this.savedFilters ] : [],
            orderBy: [null, null]
        }
    },
    mounted() {
        if (this.openTicket) {
            this.loadTicket(this.openTicket)
            // remove the ticket search param from the url
            const url = new URL(window.location.href);
            url.searchParams.delete('ticket');
            window.history.replaceState({}, document.title, url.toString());
        }

        nextTick(() => {
            if (this.filter.assignedTo) {
                this.assignee = `user:${this.filter.assignedTo}`
            }

            if (this.filter.team) {
                this.assignee = `team:${this.filter.team}`
            }
        })

        router.on('success', () => {
            this.loading = false;
        })
    },
    computed: {
        statChart() {
            const data = {
                labels: [],
                datasets: []
            }

            const colors = {
                priorities: {
                    1: '#D5ECF4',
                    2: '#BBD8A0',
                    3: '#FFEF80',
                    4: '#FFD480',
                    5: '#FF9F80',
                },
                statuses: {
                    "new": "#CEAADD",
                    "in-progress": "#95CCF1",
                    "need-more-information": "#FFCF83",
                    "waiting-on-client": "#FEE581",
                    // "resolved": "#93E9B7",
                    "reopened": "#FF9F80"
                }
            }

            const priorityDatasets = [1,2,3,4,5].map(priority => {
                return {
                    label: `Priority ${priority}`,
                    borderColor: colors.priorities[priority],
                    backgroundColor: colors.priorities[priority],
                    pointStyle: false,
                    fill: 'origin',
                    data: []
                }
            })

            const statusDatasets = Object.keys(colors.statuses).map((status) => {
                return {
                    label: this.options.statuses[status],
                    borderColor: colors.statuses[status],
                    backgroundColor: colors.statuses[status],
                    pointStyle: false,
                    fill: 'origin',
                    data: []
                }
            })


            for (let daysAgo = 14; daysAgo >= 0; daysAgo--) {
                const date = new Date()
                date.setDate(date.getDate() - daysAgo)
                data.labels.push(date.toLocaleDateString())
            }

            if (this.showingChart === 'priority') {
                this.stats.priority.forEach((datum) => {
                    priorityDatasets.some((dataset) => {
                        if (dataset.label === `Priority ${datum.value}`) {
                            const d = moment.utc(datum.created_at).local().toDate();
                            const label = d.toLocaleDateString()
                            const idx = data.labels.indexOf(label)
                            dataset.data[idx] = datum.count
                            return true
                        }
                    })
                })

                this.stats.current.priority.forEach((datum) => {
                    priorityDatasets.some((dataset) => {
                        if (dataset.label === `Priority ${datum.priority}`) {
                            dataset.data[data.labels.length - 1] = datum.count
                            return true
                        }
                    })
                })

                data.datasets = priorityDatasets
            } else {
                this.stats.status.forEach((datum) => {
                    statusDatasets.some((dataset) => {
                        if (dataset.label === this.options.statuses[datum.value]) {
                            const d = moment.utc(datum.created_at).local().toDate();
                            const label = d.toLocaleDateString()
                            const idx = data.labels.indexOf(label)
                            dataset.data[idx] = datum.count
                            return true
                        }
                    })
                })

                this.stats.current.status.forEach((datum) => {
                    statusDatasets.some((dataset) => {
                        if (dataset.label === this.options.statuses[datum.status]) {
                            dataset.data[data.labels.length - 1] = datum.count
                            return true
                        }
                    })
                })

                data.datasets = statusDatasets
            }

            return {
                data,
                options: {
                    scales: {
                        y: {
                            stacked: true,
                            display: false
                        },
                        x: {
                            display: false,
                        }
                    },
                    interaction: {
                        mode: 'nearest',
                        axis: 'x',
                        intersect: false
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            mode: 'index'
                        }
                    },
                    responsive:true,
                    maintainAspectRatio: false
                }
            }
        },
        isTeamMember() {
            return this.auth.user.type === 'team'
        },
        sidebar() {
            const sidebar = [
                {
                    label: 'Watching',
                    active: () => !!this.filter.watching,
                    click: () => this.setFilter({ watching: !this.filter.watching }),
                    if: () => this.isTeamMember
                }, {
                    label: 'Recently Updated',
                    active: () => !!this.filter.recent,
                    click: () => this.setFilter({ recent: !this.filter.recent })
                }, {
                    seperator: true,
                    label: 'Types',
                    if: () => this.auth.user.permissions.includes('activity:email')
                }, {
                    label: 'Email',
                    active: () => this.filter.type.indexOf('email') > -1,
                    click: () => this.toggleFilter('type', 'email'),
                    if: () => this.auth.user.permissions.includes('activity:email')
                }, {
                    label: 'Feedback',
                    active: () => this.filter.type.indexOf('feedback') > -1,
                    click: () => this.toggleFilter('type', 'feedback'),
                    if: () => this.auth.user.permissions.includes('activity:email')
                }, {
                    label: 'Content Approval',
                    active: () => this.filter.type.indexOf('approval') > -1,
                    click: () => this.toggleFilter('type', 'approval')
                }, {
                    seperator: true,
                    label: 'Saved Filters',
                    if: () => this.isTeamMember,
                    action: {
                        icon: 'fa fa-plus -top-px relative',
                        title: 'Create a new filter',
                        handle: () => {
                            const name = prompt('What do you want to name this filter?')
                            if (name) {
                                this.saveFilter(name)
                            }
                        }
                    }
                },
                {
                    draggable: true,
                    if: () => this.isTeamMember,
                    orderChanged: (event) => {
                        if ("moved" in event) {
                            const { newIndex, oldIndex } = event.moved;
                            const [removed] = this.localSavedFilters.splice(oldIndex, 1);
                            this.localSavedFilters.splice(newIndex, 0, removed);
                            this.saveFilterOrder();
                        }
                    },
                    children: this.localSavedFilters.map(filter => ({
                        id: filter.id,
                        label: filter.name,
                        active: () => this.matchFilter(filter.filters),
                        click: () => this.setFilter(filter.filters),
                        action: {
                            icon: 'fa fa-times',
                            title: 'Delete this filter',
                            handle: () => {
                                if (!confirm('Are you sure you want to delete this filter?')) {
                                    return
                                }
                                this.deleteFilter(filter)
                            }
                        }
                    })),
                },
                {
                    seperator: true,
                    label: 'Priority',
                    if: () => this.isTeamMember
                },
                {
                    label: 'Critical Priority',
                    active: () => this.filter.priority.indexOf(6) > -1,
                    click: () => this.toggleFilter('priority', 6),
                    if: () => this.isTeamMember
                },
                {
                    label: 'Urgent Priority',
                    active: () => this.filter.priority.indexOf(5) > -1,
                    click: () => this.toggleFilter('priority', 5),
                    if: () => this.isTeamMember
                },
                {
                    label: 'High Priority',
                    active: () => this.filter.priority.indexOf(4) > -1,
                    click: () => this.toggleFilter('priority', 4),
                    if: () => this.isTeamMember
                },
                {
                    label: 'Medium Priority',
                    active: () => this.filter.priority.indexOf(3) > -1,
                    click: () => this.toggleFilter('priority', 3),
                    if: () => this.isTeamMember
                },
                {
                    label: 'Low Priority',
                    active: () => this.filter.priority.indexOf(2) > -1,
                    click: () => this.toggleFilter('priority', 2),
                    if: () => this.isTeamMember
                },
                {
                    label: 'No Priority',
                    active: () => this.filter.priority.indexOf(1) > -1,
                    click: () => this.toggleFilter('priority', 1),
                    if: () => this.isTeamMember
                },
                {
                    seperator: true,
                    label: 'Status',
                },
                {
                    label: 'New',
                    active: () => this.filter.status.indexOf('new') > -1,
                    click: () => this.toggleFilter('status', 'new'),
                },
                {
                    label: 'In Progress',
                    active: () => this.filter.status.indexOf('in-progress') > -1,
                    click: () => this.toggleFilter('status', 'in-progress'),
                },
                {
                    label: 'Need More Info',
                    active: () => this.filter.status.indexOf('need-more-information') > -1,
                    click: () => this.toggleFilter('status', 'need-more-information'),
                },
                {
                    label: 'Waiting on Client',
                    active: () => this.filter.status.indexOf('waiting-on-client') > -1,
                    click: () => this.toggleFilter('status', 'waiting-on-client'),
                },
                {
                    label: 'Reopened',
                    active: () => this.filter.status.indexOf('reopened') > -1,
                    click: () => this.toggleFilter('status', 'reopened'),
                },
                {
                    label: 'Resolved',
                    active: () => this.filter.status.indexOf('resolved') > -1,
                    click: () => this.toggleFilter('status', 'resolved'),
                }, {
                    seperator: true,
                    label: 'Category',
                },
                {
                    label: 'Support',
                    active: () => this.filter.category.indexOf('support') > -1,
                    click: () => this.toggleFilter('category', 'support'),
                },
                {
                    label: 'Billing',
                    active: () => this.filter.category.indexOf('billing') > -1,
                    click: () => this.toggleFilter('category', 'billing'),
                },
                {
                    label: 'Notification',
                    active: () => this.filter.category.indexOf('notification') > -1,
                    click: () => this.toggleFilter('category', 'notification'),
                },
                {
                    label: 'Promotion',
                    active: () => this.filter.category.indexOf('promotion') > -1,
                    click: () => this.toggleFilter('category', 'promotion'),
                },
                {
                    label: 'Other',
                    active: () => this.filter.category.indexOf('other') > -1,
                    click: () => this.toggleFilter('category', 'other'),
                },
                {
                    label: 'Spam',
                    active: () => this.filter.category.indexOf('spam') > -1,
                    click: () => this.toggleFilter('category', 'spam'),
                },
            ]
            return sidebar.filter(s => !s.if || s.if())
        },
        clientsById() {
            return this.clients.reduce((acc, client) => {
                acc[client.id] = client
                return acc
            }, {})
        },
        searchMentions() {
            const users = Object.values(this.usersById)
            const teams = Object.values(this.teamsById)
            const clients = Object.values(this.clientsById)
            const statuses = Object.entries(this.options.statuses)
            const categories = Object.entries(this.options.categories)
            const mentions = {}
            users.forEach(user => {
                if (user.type !== 'team') {
                    return;
                }

                let userName = user.name
                if (user.email) {
                    userName += ` <${user.email}>`
                }
                if (user.client_id && user.client_id in this.clientsById) {
                    userName += ` (${this.clientsById[user.client_id].name})`
                }
                mentions[`user:${user.id}`] = `User: ${userName}`
            })
            teams.forEach(team => {
                mentions[`team:${team.id}`] = `Team: ${team.name}`
            })
            clients.forEach(client => {
                mentions[`client:${client.id}`] = `Client: ${client.name}`
            })
            statuses.forEach(([status, label]) => {
                mentions[`status:${status}`] = `Status: ${label}`
            })
            categories.forEach(([category, label]) => {
                mentions[`category:${category}`] = `Category: ${label}`
            })
            return mentions
        },
        options() {
            const clientNames = {
                [null]: 'All Clients',
                'none': 'No Client',
                '--': '--',
            }
            const clients = Object.values(this.clientsById)
            clients.forEach(client => {
                clientNames[client.id] = client.name
            })
            const userNames = {}
            const teamMembers = {}
            const users = Object.values(this.usersById)
            users.forEach(user => {
                userNames[user.id] = user.name
                if (user.type == 'team' && user.is_active) {
                    teamMembers[user.id] = user.name
                }
            })

            const teamNames = {}
            const teams = Object.values(this.teamsById)
            teams.forEach(team => {
                teamNames[team.id] = team.name
            })

            const sortedTeamNames = Object.keys(teamNames).sort((a, b) => {
                if (teamNames[a] < teamNames[b]) {
                    return -1
                }
                if (teamNames[a] > teamNames[b]) {
                    return 1
                }
                return 0
            }).reduce((acc, key) => {
                acc[key] = teamNames[key]
                return acc
            }, {})

            let projects = []
            let projectNames = {
                [null]: 'All Projects',
                '--': '--',
            }

            if (this.filter.client) {
                projects = this.projects.filter(project => project.client_id === this.filter.client)
                if (projects.length <= 1) {
                    // If there is only one project, don't show the project filter
                    projectNames = null
                } else {
                    projects.forEach(project => {
                        projectNames[project.id] = project.name
                    })
                }
            }

            const assignees = []
            Object.entries(teamMembers).forEach(([id, name]) => {
                assignees.push({
                    value: `user:${id}`,
                    label: name
                })
            })
            assignees.sort((a, b) => {
                return a.label.localeCompare(b.label)
            })
            assignees.unshift({
                seperator: true,
                label: 'Users'
            })
            assignees.unshift({
                value: 'user:none',
                label: 'Unassigned'
            })
            assignees.unshift({
                value: `user:${this.auth.user.id}`,
                label: 'Mine'
            })
            assignees.unshift({
                value: null,
                label: 'Pick one...'
            })
            assignees.push({
                seperator: true,
                label: 'Teams'
            })
            Object.entries(sortedTeamNames).forEach(([id, name]) => {
                assignees.push({
                    value: `team:${id}`,
                    label: name
                })
            })

            return {
                assignees,
                clients: clientNames,
                users: userNames,
                activeTeamMembers: teamMembers,
                teams: sortedTeamNames,
                projects: projectNames,
                categories: {
                    support: 'Support',
                    billing: 'Billing',
                    notification: 'Notification',
                    promotion: 'Promotion',
                    spam: 'Spam',
                    other: 'Other'
                },
                priorities: {
                    1: 'None',
                    2: 'Low',
                    3: 'Medium',
                    4: 'High',
                    5: 'Urgent',
                    6: 'Critical'
                },
                statuses: {
                    'new': 'New',
                    'in-progress': 'In Progress',
                    'need-more-information': 'Need More Information',
                    'waiting-on-client': 'Waiting on Client',
                    'resolved': 'Resolved',
                    'reopened': 'Reopened'
                }
            }
        }
    },
    methods: {
        closeTicket(ticket) {
            if (this.activeTicket === ticket) {
                const idx = this.openTickets.findIndex(t => t === ticket)
                this.openTickets.splice(idx, 1)

                if (idx < this.openTickets.length) {
                    this.activeTicket = this.openTickets[idx]
                } else if (this.openTickets.length > 0) {
                    this.activeTicket = this.openTickets[this.openTickets.length - 1]
                } else {
                    this.activeTicket = null
                }
            } else {
                this.openTickets = this.openTickets.filter(t => t !== ticket)
            }
        },
        ticketSubject(ticket) {
            if (!this.ticketData[ticket]) {
                return 'Loading...'
            }
            if (ticket == 'new') {
                return 'New Ticket'
            }
            return this.ticketData[ticket].memo.subject
        },
        matchFilter(filter) {
            return Object.keys(filter).every(key => {
                return JSON.stringify(this.filter[key]) === JSON.stringify(filter[key])
            })
        },
        async saveFilter(name) {
            await axios.post(this.$route('activity.filter.save'), {
                name,
                filters: {
                    ...this.filter,
                    page: undefined,
                    _counter: undefined
                }
            })
            this.$inertia.reload({ only: ['savedFilters'] })
        },
        async saveFilterOrder() {
            await axios.post(this.$route('activity.filter.order'), {
                order: this.localSavedFilters.map(filter => filter.id)
            })
            this.$inertia.reload({ only: ['savedFilters'] })
        },
        async deleteFilter(filter) {
            await axios.delete(this.$route('activity.filter.delete', filter.id))
        },
        async saveBulkChanges() {
            this.bulkSaving = true
            const memoIds = this.selectedMemos.map(m => m.id);
            const keys = Object.keys(this.bulkUpdate)
            const data = keys.reduce((acc, key) => {
                if (this.bulkUpdate[key] !== null) {
                    acc[key] = this.bulkUpdate[key]
                }
                return acc
            }, {})

            const promises = memoIds.map((mid) => {
                new Promise(resolve => {
                    axios.post(
                        this.$route("activity.memo.update", mid),
                        {
                            '_method': 'PUT',
                            ...data
                        }
                    ).then(resolve)

                })
            })
            await Promise.all(promises)
            this.bulkSaving = false
            this.bulkSelect = false
            this.filter._counter++
        },
        select($event, memo) {
            if (this.bulkSelect) {
                if ($event && $event.shiftKey && this.selectedMemos.length > 0) {
                    // Get the last selected memo
                    const lastSelected = this.selectedMemos[this.selectedMemos.length - 1];

                    // Find indices of the last selected and current memo in the memos.data array
                    const lastIndex = this.memos.data.findIndex(m => m.id === lastSelected.id);
                    const currentIndex = this.memos.data.findIndex(m => m.id === memo.id);

                    if (lastIndex !== -1 && currentIndex !== -1) {
                        // Determine the range to select
                        const start = Math.min(lastIndex, currentIndex);
                        const end = Math.max(lastIndex, currentIndex);

                        // Select all memos in the range that aren't already selected
                        for (let i = start; i <= end; i++) {
                            const memoToSelect = this.memos.data[i];
                            if (!this.selectedMemos.some(m => m.id === memoToSelect.id)) {
                                this.selectedMemos.push(memoToSelect);
                            }
                        }
                    }
                } else {
                    this.selectedMemos.push(memo);
                }
            } else {
                if (this.openTickets.includes(memo.ticket_number)) {
                    this.openTickets = this.openTickets.filter(t => t !== memo.ticket_number)
                }
                this.openTickets.push(memo.ticket_number)

                if (!$event || !$event.metaKey) {
                    this.activeTicket = memo.ticket_number
                } else {
                    this.loadTicket(memo.ticket_number)
                }
            }
        },
        deselect(memo) {
            const idx = this.selectedMemos.findIndex(m => m.id === memo.id)
            if (idx > -1) {
                this.selectedMemos.splice(idx, 1)
            }
        },
        nextPage() {
            this.filter.page++
        },
        prevPage() {
            this.filter.page--
        },
        toggleFilter(key, value) {
            const idx = this.filter[key].indexOf(value);
            if (idx === -1) {
                this.filter[key].push(value);
            } else {
                this.filter[key].splice(idx, 1);
            }
        },
        updateFilter(args, resetPage = false) {
            this.filter[args.key] = args.value

            if (resetPage) {
                this.filter.page = 1
            }
        },
        setFilter(filter, replace = false) {
            if (replace) {
                this.filter.status = ['new', 'in-progress', 'need-more-information', 'waiting-on-client', 'reopened']
                this.filter.priority = []
                this.filter.team = null
                this.filter.assignedTo = null
                this.filter.watching = false
                this.filter.recent = false
                this.filter.sort = null
                this.filter.direction = null
            }

            Object.keys(filter).forEach(key => {
                if (key == 'search') {
                    this.search = filter[key]
                }

                this.updateFilter({ key, value: filter[key] })
            })
        },
        loadSearchedTicket() {
            if (String(this.ticketSearch).match(/^[0-9]+$/) || String(this.ticketSearch).match(/^[a-zA-Z0-9-]+$/)) {
                this.loadTicket(this.ticketSearch, true)
                this.ticketSearch = null
                this.searchOpen = false
            }
        },
        loadTicket(ticket, selectTicket = false) {
            if (String(ticket).match(/^[0-9]+$/) || String(ticket).match(/^[a-zA-Z0-9-]+$/)) {
                axios.get(this.$route('activity.memo', ticket), {
                    headers: {
                        'Accept': 'application/json'
                    }
                }).then(res => {
                    this.ticketData[ticket] = res.data
                    if (selectTicket) {
                        this.select(null, res.data.memo)
                    }
                }).catch(error => {
                    // if 404, show alert
                    if (error.response && error.response.status === 404) {
                        window.alert(`Ticket #${ticket} not found`)
                    } else {
                        window.alert("Failed to load ticket")
                    }
                })
            }
        },
        searchTickets() {
            this.filter.search = this.search
            this.filter.page = 1;
            this.loadTickets()
        },
        loadTickets () {
            this.loading = true
            this._loadTickets()
        },
        _loadTickets: debounce(async function () {
            const filter = {}
            Object.keys(this.filter).forEach(key => {
                if (this.filter[key] !== null && this.filter[key] !== '' && this.filter[key] !== 'null') {
                    filter[key] = this.filter[key]
                }
            })

            if (!this.isTeamMember) {
                delete filter['client']
            }

            if ('watching' in filter && !filter.watching) {
                delete filter['watching']
            }

            if ('recent' in filter && !filter.recent) {
                delete filter['recent']
            }

            delete filter['_counter']

            this.loading = true
            this.$inertia.visit(
                this.$route("activity"),
                {
                    data: filter,
                    replace: true,
                    preserveState: true,
                    preserveScroll: true
                }
            )
        }, 1000),
    },
    watch: {
        activeTicket: function(val) {
            if (val) {
                // quickly scroll to the top of the page
                window.scrollTo({
                    top: 0,
                    behavior: 'instant'
                })
                this.loadTicket(val)

                // Update the URL to be /activity/{ticket} (without loading the page)
                history.pushState(null, '', `/activity/${val}`)
            } else {
                this.loadTickets()
            }
        },
        bulkSelect: function(val) {
            if (!val) {
                this.selectedMemos = []
            }
        },
        searchOpen() {
            if (this.searchOpen) {
                nextTick(() => {
                    this.$refs.searchInput.focus()
                })
            }
        },
        search: function(val) {
            if (!val) { // if they cleared the search, reload the tickets
                this.searchTickets()
            }
        },
        "filter.assignedTo": function(val) {
            if (this.assignee != `user:${val}` && val) {
                this.assignee = `user:${val}`
            }
        },
        "filter.team": function(val) {
            if (this.assignee != `team:${val}` && val) {
                this.assignee = `team:${val}`
            }
        },
        assignee: {
            handler: function(val) {
                if (val) {
                    const match = val.match(/^(user|team):(.+)$/)
                    if (match) {
                        if (match[1] === 'user' && match[2] !== this.filter.assignedTo) {
                            this.filter.assignedTo = match[2]
                        } else if (match[1] === 'team' && match[2] !== this.filter.team) {
                            this.filter.team = match[2]
                        }
                    }
                } else {
                    this.filter.assignedTo = null
                    this.filter.team = null
                }
            }
        },
        filter: {
            handler: function () {
                if (!this.filter.assignedTo && !this.filter.team) {
                    this.assignee = null
                }
                this.loadTickets()
            },
            deep: true
        },
        savedFilters() {
            this.localSavedFilters = this.savedFilters ? [...this.savedFilters] : []
        },
        orderBy: {
            handler(newValue) {
                this.filter.page = 1
                this.filter.sort = newValue[0]
                this.filter.direction = newValue[1]
                this.filter._counter++
            },
            deep: true
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
