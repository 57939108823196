<template>
    <btn
        :green="green"
        :red="red"
        :orange="orange"
        :yellow="yellow"
        :white="white"
        :black="black"
        :gray="gray"
        :outline="outline"
        @click="toggle"
        ref="dropdown"
        class="border-l"
        :disabled="disabled"
    >
        <slot name="opener">
            <i class="fa fa-angle-down"></i>
        </slot>
    </btn>
    <Teleport to="body">
        <div
            class="absolute mt-1 w-full rounded-md bg-white shadow-lg text-sm z-9998"
            :style="{
                top: position.top ? (position.top + 'px') : null,
                bottom: position.bottom ? (position.bottom + 'px') : null,
                left: position.left + 'px',
                width: position.width + 'px'
            }"
            v-if="open"
        >
            <div ref="content">
                <slot></slot>
            </div>
        </div>
    </Teleport>
</template>

<script>
import { nextTick } from 'vue'
import btn from './btn.vue'

export default {
    name: 'BtnDropdown',
    components: { btn },
    props: {
        block: {
            type: Boolean,
            default: false
        },
        outline: {
            type: Boolean,
            default: false
        },
        green: {
            type: Boolean,
            default: false
        },
        red: {
            type: Boolean,
            default: false
        },
        orange: {
            type: Boolean,
            default: false
        },
        yellow: {
            type: Boolean,
            default: false
        },
        white: {
            type: Boolean,
            default: false
        },
        black: {
            type: Boolean,
            default: false
        },
        gray: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            open: false,
            position: {
                popUp: false,
                top: 0,
                left: 0,
                width: 0,
            }
        }
    },
    mounted() {
        document.addEventListener("click", this.close)
        // close on scroll or resize
        window.addEventListener("dropdown-close", this.close)
        window.addEventListener("scroll", this.reposition)
        window.addEventListener("resize", this.reposition)
        this.reposition();
    },
    beforeUnmount() {
        document.removeEventListener("click", this.close)
        window.removeEventListener("scroll", this.reposition)
        window.removeEventListener("resize", this.reposition)
        window.removeEventListener("dropdown-close", this.close)
    },
    methods: {
        toggle() {
            if (!this.open) {
                window.dispatchEvent(new Event("dropdown-close"))
                nextTick(() => {
                    this.open = true
                })
            } else {
                this.open = !this.open
            }
        },
        reposition() {
            const offset = this.$refs.dropdown.$el.getBoundingClientRect()
            this.position.popUp = window.innerHeight - offset.y < 300
            this.position.width = null
            nextTick(() => {
                if (this.$refs.content) {
                    this.$refs.content.style.position = 'absolute'
                }
                nextTick(() => {
                    if (this.$refs.content) {
                        this.position.width = this.width ? this.width : this.$refs.content.clientWidth
                        this.position.left = offset.x - this.position.width + offset.width;
                        this.$refs.content.style.position = 'relative'
                    }
                })
            })
            if (this.position.popUp) {
                this.position.bottom = window.innerHeight - offset.y - window.scrollY
                this.position.top = null
            } else {
                this.position.top = offset.y + window.scrollY + offset.height
                this.position.bottom = null
            }
        },
        close() {
            this.open = false
        },
    },
    computed: {
    },
    watch: {
        open() {
            if (this.open) {
                this.reposition();
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
