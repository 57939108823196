<template>
    <Layout :auth='auth'>
        <Head title="Trello Tools" />
        <template v-slot:title>Trello Tools</template>
        <section>
            <template v-if="connected">
                <div class="flex flex-col gap-2">
                    <hgselect
                        v-model="config.board"
                        :options="options.trelloBoards"
                        placeholder="Select Board"
                        @open="loadTrelloBoards"
                    />
                    <hgselect
                        v-model="config.list"
                        :options="options.trelloLists"
                        placeholder="Select List"
                    />
                    <input type="file" @change="uploadCSVFile" accept="text/csv" ref="csvFileInput" />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                    <div v-for="row in csvData" :key="row.name" class="trello-card bg-white rounded-lg shadow-md p-4 transition-shadow duration-200">
                        <div class="flex flex-col gap-2">
                            <h3 class="font-medium text-gray-800">
                                <template v-if="row.creating">
                                    <i class="fa fa-spinner-third fa-spin"></i>
                                </template>
                                <template v-else-if="row.created">
                                    <i class="fa fa-check-circle text-green-500"></i>
                                </template>
                                <template v-else-if="row.error">
                                    <i class="fa fa-times-circle text-red-500"></i>
                                </template>
                                {{ row.name || 'Untitled Card' }}
                            </h3>
                            <div class="text-sm text-gray-600">
                                {{ row.description }}
                            </div>
                            <div class="text-xs text-gray-500">
                                {{ dt(row.due) }}
                            </div>
                            <div class="flex flex-wrap items-center gap-2 mt-2">
                                <template v-for="(label, idx) in row.labels" :key="idx">
                                    <span class="px-2 py-1 text-xs rounded bg-blue-100 text-blue-800">
                                        {{ label }}
                                    </span>
                                </template>
                            </div>
                            <template v-if="row.error">
                                <div class="text-red-500">
                                    {{ row.error }}
                                </div>
                            </template>
                            <template v-else-if="row.url">
                                <a
                                    :href="row.url"
                                    target="_blank"
                                    class="text-green-500 hover:text-green-700 text-sm"
                                >
                                    View Card
                                </a>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center mt-4" v-if="csvData && csvData.length > 0">
                    <btn
                        :disabled="!config.board || !config.list || !csvData || csvData.length === 0"
                        @click="createCards"
                    >
                        Create {{ csvData.length }} Card<template v-if="csvData.length > 1">s</template>
                    </btn>
                </div>
            </template>
            <template v-else>
                <div class="flex flex-col gap-2">
                    <btn
                        :href="route('trello.start')"
                    >Connect Trello</btn>
                </div>
            </template>
        </section>
    </Layout>
</template>

<script>
import axios from 'axios'
import btn from "../../Common/btn.vue"
import Layout from "../../Common/Layout.vue"
import hgselect from "../../Common/hgselect.vue"
import { Head, Link } from '@inertiajs/vue3'
import Papa from 'papaparse';
import moment from 'moment-timezone';

export default {
  name: 'EversiteBillingsReport',
  components: {
      Layout, Link, Head, hgselect, btn
  },
  props: {
    auth: {
        type: Object,
        required: true
    },
  },
  data() {
      return {
        config: {
            board: null,
            list: null
        },
        connected: false,
        boards: [],
        lists: [],
        labels: [],
        csvData: null,
      }
  },
  async mounted() {
    const { data } = await axios.get(this.$route('trello.check'))
    this.connected = data.result
    if (this.connected) {
        this.loadTrelloBoards()
    }
  },
  computed: {
    options() {
        return {
            trelloBoards: this.boards.reduce((acc, board) => {
                acc[board.id] = board.name
                return acc
            }, {}),
            trelloLists: this.lists.reduce((acc, list) => {
                acc[list.id] = list.name
                return acc
            }, {}),
        }
    }
  },
  methods: {
    route(r) {
        return this.$route(r)
    },
    dt(str) {
        return moment.utc(str).local().format('MMM DD, YYYY h:mm a')
    },
    async loadTrelloBoards() {
        const { data } = await axios.get(this.$route('trello.boards'))
        this.boards = data
    },
    async loadTrelloLists(boardId) {
        this.lists = []
        const { data } = await axios.get(this.$route('trello.lists', boardId))
        this.lists = data
    },
    async loadTrelloLabels(boardId) {
        this.labels = []
        const { data } = await axios.get(this.$route('trello.labels', boardId))
        this.labels = data
    },
    uploadCSVFile(event) {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;

            const result = Papa.parse(text, {
                header: true,
                skipEmptyLines: true
            });
            this.csvData = result.data;
            // break the labels into an array
            this.csvData.forEach(row => {
                // check if row.due is ISO 8601 format
                if (row.due && !row.due.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/)) {
                    row.error = "Due date must be in ISO 8601 format (YYYY-MM-DDTHH:MM:SSZ) - UTC Time"
                }
                row.labels = row.labels.split(',').map(label => label.trim())
            })
        };
        reader.readAsText(file);
    },
    async createCards() {
        if (!this.config.board || !this.config.list || !this.csvData || this.csvData.length === 0) {
            return
        }

        const promises = this.csvData.map((row, idx) => new Promise((resolve, reject) => {
            row.creating = true
            axios.post(this.$route('trello.create.card'), {
                board: this.config.board,
                list: this.config.list,
                name: row.name,
                description: row.description,
                url: row.url,
                due: row.due,
                labels: row.labels,
                pos: idx
            }).then(response => {
                row.creating = false
                row.created = true
                row.url = response.data.url
                resolve(response.data)
            }).catch(error => {
                row.creating = false
                row.error = error.response.data.message
                reject(error)
            })
        }))

        await Promise.all(promises)
    }
  },
  watch: {
    'config.board'() {
        this.loadTrelloLists(this.config.board)
        this.loadTrelloLabels(this.config.board)
    }
  }
}
</script>

<style lang="postcss" scoped>
.trello-card {
    @apply border border-gray-200;
    min-height: 100px;
}
</style>
