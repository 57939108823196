<template>
    <div class="relative editor" :class="{
        'plain-text': plainText,
        'with-icon': !!icon,
        [backgroundColor]: true
    }">
        <span v-if="icon" :key="icon">
            <i class="absolute z-10 text-lg text-gray-400 top-[7px] left-2" :class="icon"></i>
        </span>
        <div v-if="editor && !plainText && (editorHasFocus || modelValue)" @click.stop.prevent>
            <bubble-menu class="inline-flex rounded-md shadow-sm bubble-menu isolate" :editor="editor" :tippy-options="{ duration: 100 }" @click.stop.prevent>
                <button
                    @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                    :disabled="!editor.can().chain().focus().toggleHeading({ level: 5 }).run()"
                    :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
                >
                    <i class="far fa-fw fa-heading"></i>
                </button>
                <button
                    @click="editor.chain().focus().toggleBold().run()"
                    :disabled="!editor.can().chain().focus().toggleBold().run()"
                    :class="{ 'is-active': editor.isActive('bold') }"
                >
                    <i class="far fa-fw fa-bold"></i>
                </button>
                <button
                    @click="editor.chain().focus().toggleItalic().run()"
                    :disabled="!editor.can().chain().focus().toggleItalic().run()"
                    :class="{ 'is-active': editor.isActive('italic') }"
                >
                    <i class="far fa-fw fa-italic"></i>
                </button>
                <button
                    @click="editor.chain().focus().toggleBulletList().run()"
                    :class="{ 'is-active': editor.isActive('bulletList') }"
                >
                    <i class="far fa-fw fa-list"></i>
                </button>
                <button
                    @click="editor.chain().focus().toggleOrderedList().run()"
                    :class="{ 'is-active': editor.isActive('orderedList') }"
                >
                    <i class="far fa-fw fa-list-ol"></i>
                </button>
                <button
                    @click="editor.chain().focus().toggleBlockquote().run()"
                    :class="{ 'is-active': editor.isActive('blockquote') }"
                >
                    <i class="fas fa-fw fa-quote-left"></i>
                </button>
                <button
                    @click="setLink"
                    :class="{ 'is-active': editor.isActive('link') }"
                >
                    <i class="fas fa-fw fa-link"></i>
                </button>
                <button
                    @click="editor.chain().focus().unsetLink().run()"
                    :disabled="!editor.isActive('link')"
                >
                    <i class="fas fa-fw fa-link-slash"></i>
                </button>
                <button
                    @click="editor.chain().focus().toggleCode().run()"
                    :disabled="!editor.can().chain().focus().toggleCode().run()"
                    :class="{ 'is-active': editor.isActive('code') }"
                >
                    <i class="far fa-fw fa-code"></i>
                </button>
            </bubble-menu>
        </div>
        <div
            class="max-w-full prose"
            :class="{
                'bg-yellow-100': backgroundColor == 'yellow',
                'bg-white': backgroundColor == 'white'
            }"
        >
            <editor-content
                :editor="editor"
            />
        </div>
    </div>
</template>

<script>
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-3";
import suggestion from './Editor/suggestion.js'
import NoNewLine from './Editor/NoNewLine.js'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import FileHandler from '@tiptap-pro/extension-file-handler'

export default {
    components: {
        EditorContent,
        BubbleMenu
    },
    props: {
        icon: {
            type: String,
            default: ''
        },
        plainText: {
            type: Boolean,
            default: false
        },
        singleLine: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        modelValue: {
            type: String,
            default: ""
        },
        mentionables: {
            type: Object
        },
        backgroundColor: {
            type: String,
            default: 'white'
        },
        upload: {
            type: Function,
            default: null
        }
    },

    emits: ["update:modelValue"],

    data() {
        return {
            editorHasFocusTimeout: false,
            editorHasFocus: false,
            editor: null
        };
    },

    watch: {
        modelValue(value) {
            let html = this.editor.getHTML()
            if (this.plainText) {
                html = this.toPlainText(html);
            }

            const isSame = html === value;

            if (isSame) {
                return;
            }

            value = this.addMentionMarkup(value);

            this.editor.commands.setContent(value, false);
        }
    },

    mounted() {
        let html = this.modelValue;

        const extensions = [];
        if (this.singleLine) {
            extensions.push(NoNewLine);
        }

        if (this.plainText) {
            extensions.push(Document);
            extensions.push(Text);
            extensions.push(Paragraph);
        } else {
            extensions.push(StarterKit);
            extensions.push(Link.configure({
                openOnClick: false
            }));

            if (this.upload) {
                extensions.push(Image.configure({
                    inline: true
                }));
                extensions.push(FileHandler.configure({
                    allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
                    onDrop: (currentEditor, files, pos) => {
                        files.forEach(file => {
                            this.upload(file).then(url => {
                                currentEditor.chain().insertContentAt(pos, {
                                    type: 'image',
                                    attrs: {
                                        src: url,
                                    },
                                }).focus().run()
                            }).catch(error => {
                                alert('Error uploading file')
                                console.error(error)
                            })
                        })
                    },
                    onPaste: (currentEditor, files, htmlContent) => {
                        files.forEach(file => {
                            if (htmlContent) {
                                // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
                                // you could extract the pasted file from this url string and upload it to a server for example
                                console.log(htmlContent) // eslint-disable-line no-console
                                return false
                            }

                            this.upload(file).then(url => {
                                currentEditor.chain().insertContentAt(currentEditor.state.selection.anchor, {
                                    type: 'image',
                                    attrs: {
                                        src: url,
                                    },
                                }).focus().run()
                            }).catch(error => {
                                alert('Error uploading file')
                                console.error(error)
                            })
                        })
                    },
                }))
            }
        }

        extensions.push(Placeholder.configure({
            placeholder: this.placeholder
        }))

        if (this.mentionables && Object.keys(this.mentionables).length > 0) {
            extensions.push(Mention.configure({
                renderHTML: ({ options, node }) => {
                    const id = node.attrs.label || node.attrs.id;
                    return [
                        "span",
                        {
                            class: 'mention',
                            'data-type': "mention",
                            'data-label': id in this.mentionables ? this.mentionables[id] : id,
                            'data-id': node.attrs.id,
                        },
                        `${options.suggestion.char}${id in this.mentionables ? this.mentionables[id] : id}`,
                    ];
                },
                suggestion: suggestion(this.mentionables),
            }))

            html = this.addMentionMarkup(html);
        }

        this.editor = new Editor({
            extensions,
            content: html,
            onUpdate: () => {
                let html = this.editor.getHTML()
                if (html == "<p></p>") {
                    html = ""
                }
                if (this.plainText) {
                    html = this.toPlainText(html);
                }
                this.$emit("update:modelValue", html);
            },
            onFocus: () => {
                clearTimeout(this.editorHasFocusTimeout);
                this.editorHasFocus = true;
            },
            onBlur: () => {
                this.editorHasFocusTimeout = setTimeout(() => {
                    this.editorHasFocus = false;
                }, 100);
            },
        });
    },

    methods: {
        addMentionMarkup(html) {
            return (html || '').replace(/\[([^\]]+)]\(([^)]+)\)/gm, (match, label, id) => {
                return `<span class="mention" data-type="mention" data-id="${id}" data-label="${label}">@${label}</span>`;
            });
        },
        toPlainText(html) {
            // convert mentions to markup style links with dom
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const mentions = doc.querySelectorAll('span.mention');
            mentions.forEach((mention) => {
                const id = mention.getAttribute('data-id');
                const label = mention.getAttribute('data-label');
                const link = `[${label}](${id})`;
                mention.replaceWith(link);
            });

            return doc.body.innerText;
        },
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
                return
            }

            // update link
            this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
        }
    },

    beforeUnmount() {
        this.editor.destroy();
    }
};
</script>

<style lang="postcss" scoped>
.bubble-menu {
    @apply isolate inline-flex shadow bg-slate-600 p-2 rounded-full gap-1;

    button {
        @apply border-none bg-transparent text-white text-base font-medium py-1 px-1.5 opacity-75 rounded-md;

        &:hover, &.is-active {
            @apply bg-white/10 opacity-100;
        }

        &:disabled{
            @apply opacity-30 cursor-not-allowed;
        }
    }
}
</style>

<style lang="postcss">
.editor {
    [contenteditable] {
        &.white {
            background-color: white;
        }
        &.yellow {
            background-color: #fdf9c9;
        }
        border: 1px solid rgb(209, 213, 219);
        border-radius: 6px;
    }
    &.with-icon [contenteditable] {
        padding-left: 2rem !important;
    }
    /* overflow: hidden; */
    outline: none !important;
    &:focus-within [contenteditable] {
        border-color: rgb(147,197,253);
        outline: 2px solid transparent;
        outline-offset: 2px;
        box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgb(147, 197, 253) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    }
    &.plain-text{
        .tiptap{
            padding: .375rem 1.125rem;
        }
    }
    div.tiptap {
        padding: 1rem 1.125rem;
        font-size: 1rem;
        p.is-editor-empty:first-child::before {
            color: rgb(107, 114, 128);
            content: attr(data-placeholder);
            float: left;
            height: 0;
            pointer-events: none;
        }
        a {
            color: #68CEF8;
            text-decoration: underline;
        }
        span.mention,
        span.suggestion {
            background-color: #fef9c3;
            border: 1px solid #facc15;
            padding: 0.15rem 0.575rem;
            font-size: 0.875rem;
            font-weight: 600;
            border-radius: 1rem;
        }
        h1,h2,h3,h4,h5,h6 {
            font-size: 1.25rem;
            font-weight: bold;
            line-height: 1.1;
        }
        blockquote {
            padding-left: 1rem;
            border-left: 2px solid rgba(#0D0D0D, 0.1);
        }
    }
}
</style>
