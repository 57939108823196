<template>
    <tr
        @click.prevent.stop="rowClick"
        class="transition-colors duration-200 ease-in-out cursor-pointer"
        :class="{
            'bg-blue-100 hover:bg-blue-200': selected,
            'bg-red-100 hover:bg-red-200': requiresImmediateAttention,
            'bg-white hover:bg-gray-200': !memo.is_internal && !requiresImmediateAttention && !selected,
            'bg-yellow-50 hover:bg-yellow-100': memo.is_internal && !selected,
            'select-none': selectable
        }"
    >
        <!-- Checkbox column (only shown when selectable) -->
        <td
            class="py-2 px-4 whitespace-nowrap"
            v-if="selectable"
        >
            <checkbox
                :modelValue="selected"
                @click="rowClick"
            />
        </td>
        <!-- ID column -->
        <td v-if="visibleColumns.includes('ticket_number')" class="py-2 px-4 whitespace-nowrap">
            <div class="flex items-center gap-2">
                <span class="text-sm text-gray-500">#{{ memo.ticket_number }}</span>
            </div>
        </td>
        <!-- Status column -->
        <td v-if="visibleColumns.includes('status')" class="py-2 px-4 whitespace-nowrap">
            <div class="flex items-center gap-2">
                <span
                    :class="'inline-flex gap-x-1 items-center text-sm font-medium text-' + status(memo.status).color"
                >
                    <i
                        :class="{
                            [status(memo.status).color]: true,
                            [status(memo.status).icon]: true
                        }"
                    ></i>
                    {{ status(memo.status).label }}
                </span>
            </div>
        </td>
        <!-- Priority column -->
        <td v-if="visibleColumns.includes('priority')" class="py-2 px-4 whitespace-nowrap">
            <div class="flex items-center gap-2">
                <span
                    v-if="isTeamMember"
                    :class="'inline-flex gap-x-1 items-center text-sm font-medium text-' + priority(memo.priority).color"
                >
                    <i
                        :class="{
                            [priority(memo.priority).color]: true,
                            [priority(memo.priority).icon]: true
                        }"
                    ></i>
                    {{ priority(memo.priority).label }}
                </span>
            </div>
        </td>
        <!-- Category column -->
        <td v-if="visibleColumns.includes('category')" class="py-2 px-4 whitespace-nowrap">
            <div class="flex items-center gap-2">
                <span v-if="memo.category" class="text-sm text-white rounded-sm px-1.5" :class="'bg-' + category(memo.category).color">{{ category(memo.category).label }}</span>
            </div>
        </td>
        <!-- Subject column -->
        <td v-if="visibleColumns.includes('subject')" class="py-2 px-4 whitespace-nowrap">
            <div class="flex items-center gap-2">
                <div class="text-sm truncate max-w-[25vw] min-w-[300px]">{{ memo.subject }}</div>
                <span v-if="memo.comment_count" class="text-sm text-gray-500 bg-gray-200 rounded-sm px-1.5">{{ memo.comment_count }}</span>
            </div>
        </td>
        <!-- Client column -->
        <td v-if="visibleColumns.includes('client')" class="py-2 px-4 whitespace-nowrap">
            <div class="flex items-center gap-2">
                <a
                    v-if="memo.client && isTeamMember"
                    href="#"
                    class="text-sm hover:underline"
                    @click.stop.prevent="$emit('filter', {key: 'client', value: memo.client.id})"
                >{{ memo.client.name }}</a>
            </div>
        </td>
        <!-- Assigned User column -->
        <td v-if="visibleColumns.includes('assigned')" class="py-2 px-4 whitespace-nowrap">
            <template v-if="memo.assigned_to">
                <span v-if="noLinks" class="text-sm">{{ userName(memo.assigned_to) }}</span>
                <a
                    v-else
                    href="#"
                    class="text-sm inline hover:underline"
                    @click.stop.prevent="$emit('filter', {key: 'assignedTo', value: memo.assigned_to})"
                >{{ userName(memo.assigned_to) }}</a>
            </template>
        </td>
        <!-- Assigned Team column -->
        <td v-if="visibleColumns.includes('team')" class="py-2 px-4 whitespace-nowrap">
            <template v-if="memo.assigned_team">
                <span v-if="noLinks" class="text-sm">{{ teamName(memo.assigned_team) }}</span>
                <a
                    v-else
                    href="#"
                    class="text-sm inline hover:underline"
                    @click.stop.prevent="$emit('filter', {key: 'team', value: memo.assigned_team})"
                >{{ teamName(memo.assigned_team) }}</a>
            </template>
        </td>
        <!-- Created Time column -->
        <td v-if="visibleColumns.includes('created_at')" class="py-2 px-4 whitespace-nowrap">
            <div class="flex items-center gap-2">
                <tooltip :title="dt(memo.created_at)"><span class="text-sm">{{ ago(memo.created_at) }}</span></tooltip>
            </div>
        </td>
        <!-- Last Activity Time column -->
        <td v-if="visibleColumns.includes('last_activity_at')" class="py-2 px-4 whitespace-nowrap">
            <div class="flex items-center gap-2">
                <tooltip v-if="memo.last_activity_at" :title="dt(memo.last_activity_at)"><span class="text-sm">{{ ago(memo.last_activity_at) }}</span></tooltip>
            </div>
        </td>
    </tr>
</template>

<script>
import moment from "moment"
import checkbox from "../../../Common/checkbox.vue"
import tooltip from "../../../Common/tooltip.vue"
import avatarMixin from "../../../Common/Mixins/avatar.js"

export default {
    name: 'MemoRow',
    components: { checkbox, tooltip },
    mixins: [ avatarMixin ],
    props: {
        teamsById: {
            type: Object,
            default: () => ({})
        },
        usersById: {
            type: Object,
            default: () => ({})
        },
        memo: {
            type: Object,
        },
        isTeamMember: {
            type: Boolean
        },
        noLinks: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        visibleColumns: {
            type: Array,
            required: true
        }
    },
    methods: {
        rowClick($event) {
            this.$emit(this.selectable && this.selected ? 'deselect' : 'select', $event)
        },
        category(category) {
            const categories = {
                'support': {
                    label: 'Support',
                    icon: 'fa-fw fad fa-hand-holding-heart',
                    color: 'blue-500',
                },
                'billing': {
                    label: 'Billing',
                    icon: 'fa-fw fad fa-hand-holding-dollar',
                    color: 'green-500',
                },
                'notification': {
                    label: 'Notification',
                    icon: 'fa-fw fad fa-bell',
                    color: 'yellow-500',
                },
                'promotion': {
                    label: 'Promotion',
                    icon: 'fa-fw fad fa-megaphone',
                    color: 'red-500',
                },
                'spam': {
                    label: 'Spam',
                    icon: 'fa-fw fad fa-envelope-open-text',
                    color: 'red-500',
                },
                'other': {
                    label: 'Other',
                    icon: 'fa-fw fad fa-question-circle',
                    color: 'gray-500',
                },
            }
            return category in categories ? categories[category] : category
        },
        dt (value) {
            const date = moment.utc(value).local()
            if (date.isSame(moment(), 'day')) {
                return date.format('h:mma');
            } else if (date.isSame(moment(), 'year')) {
                return date.format('MMM Do, h:mma');
            }
            return date.format('MMMM Do YYYY, h:mma');
        },
        ago (value) {
            return moment.utc(value).fromNow();
        },
        olderThanAMonth(value){
            let today = moment.utc();
            let created_at = moment.utc(value);
            return today.diff(created_at, 'months', true) >= 1;
        },
        priority(priority) {
            const priorities = {
                '1': {
                    label: 'None',
                    icon: 'fa-fw fad fa-face-sleeping',
                    color: 'gray-500',
                },
                '2': {
                    label: 'Low',
                    icon: 'fa-fw fad fa-face-thinking',
                    color: 'blue-500',
                },
                '3': {
                    label: 'Medium',
                    icon: 'fa-fw fad fa-face-smile',
                    color: 'green-500',
                },
                '4': {
                    label: 'High',
                    icon: 'fa-fw fad fa-face-anxious-sweat',
                    color: 'orange-500',
                },
                '5': {
                    label: 'Urgent',
                    icon: 'fa-fw fad fa-face-scream',
                    color: 'red-500',
                },
                '6': {
                    label: 'Critical',
                    icon: 'fa-fw fad fa-siren',
                    color: 'red-500',
                }
            }
            return priority in priorities ? priorities[priority] :priority
        },
        status(status) {
            const statuses = {
                'new': {
                    label: 'New',
                    icon: 'fad fa-sparkles',
                    color: 'blue-500',
                },
                'in-progress': {
                    label: 'In Progress',
                    icon: 'fad fa-cogs',
                    color: 'orange-500',
                },
                'need-more-information': {
                    label: 'Need More Information',
                    icon: 'fad fa-message-question',
                    color: 'red-500',
                },
                'waiting-on-client': {
                    label: 'Waiting on Client',
                    icon: 'fad fa-user-clock',
                    color: 'violet-500',
                },
                'resolved': {
                    label: 'Resolved',
                    icon: 'fad fa-check-circle',
                    color: 'green-500',
                },
                'reopened': {
                    label: 'Reopened',
                    icon: 'fa-fw fad fa-exclamation-circle',
                    color: 'orange-500',
                }
            }
            return status in statuses ? statuses[status] : status
        }
    },
    computed: {
        requiresImmediateAttention() {
            return this.memo.status !== 'resolved' && this.memo.priority == 6;
        },
        isFromTeamMember() {
            const createdBy = this.memo.created_by;
            if (createdBy) {
                if ('email' in createdBy && createdBy.email) {
                    return createdBy.email.match(/@legnd.com/)
                }
                if ('id' in createdBy && createdBy.id in this.usersById) {
                    const user = this.usersById[createdBy.id];
                    return user.email.match(/@legnd.com/)
                }
            }
            return false;
        }
    }
}
</script>
