<template>
    <Layout :auth='auth'>
        <Head :title="user ? user.name : 'Create User'" />
        <template v-slot:title>
            <template v-if="user">{{ user.name }}</template>
            <template v-else>Create User</template>
        </template>
        <template v-slot:actions>
            <btn v-if="editingProfile" white @click="save" :loading="saving">
                Save Profile
            </btn>
            <btn v-else-if="user" white @click="save" :loading="saving">
                Save User
            </btn>
            <btn v-else white @click="save" :loading="saving">
                Create User
            </btn>
        </template>

        <form action="#" method="POST" @submit.prevent="save">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        <template v-if="editingProfile">Your Profile</template>
                        <template v-else>User Information</template>
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        This information will be used in various places in the
                        app.
                    </p>
                    <div v-if="editingProfile" class="mt-2 text-sm">
                        <a
                            v-if="trello_connected === false"
                            href="#"
                            @click.prevent="connectTrello"
                            class="text-blue-600 hover:underline"
                        >
                            <i class="fab fa-trello"></i>
                            Connect to Trello
                        </a>
                        <div class="flex gap-2" v-else-if="trello_connected">
                            <span
                                class="text-green-800"
                            >
                            <i class="fab fa-trello"></i>
                            Connected to Trello
                            </span>
                            &bull;
                            <a
                                href="#"
                                @click.prevent="disconnectTrello"
                                class="text-red-600 hover:underline"
                            >Disconnect</a>
                        </div>
                    </div>
                    <template v-if="!editingProfile">
                        <label
                            class="mt-4 flex items-center"
                            @click="is_active = !is_active"
                        >
                            <toggle v-model="is_active" />
                            User is Active
                        </label>
                        <label
                            class="mt-4 flex flex-col"
                        >
                            User Type
                            <hgselect
                                v-model="type"
                                :options="userTypeOptions"
                                class="mt-1"
                            />
                        </label>
                        <label
                            class="mt-4 flex flex-col"
                            v-if="type == 'client'"
                        >
                            Client
                            <hgselect
                                v-model="client_id"
                                :options="clients"
                                class="mt-1"
                            />
                        </label>
                    </template>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div>
                        <label
                            for="name"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Name
                        </label>
                        <div class="rounded-md shadow-sm">
                            <input
                                type="text"
                                name="name"
                                v-model="name"
                                class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                        <error v-if="errors.name">{{
                            errors.name[0]
                        }}</error>
                    </div>
                    <div class="mt-5">
                        <label
                            for="email"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Email
                        </label>
                        <div class="rounded-md shadow-sm">
                            <input
                                name="email"
                                v-model="email"
                                type="email"
                                class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                        <error v-if="errors.email">{{
                            errors.email[0]
                        }}</error>
                    </div>
                    <div class="mt-5">
                        <label
                            for="photo"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Photo
                        </label>
                        <div class="mt-2">
                            <div class="flex items-center">
                                <avatar v-if="user" :user="user" size='12' />
                                <input
                                    ref="photo"
                                    class="hidden"
                                    type="file"
                                    @change='photoChanged'
                                />
                                <button
                                    @click="$refs.photo.click()"
                                    type="button"
                                    class="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    :class="{
                                        'ml-5': user,
                                        'ml-0': !user,
                                    }"
                                >
                                    Change
                                </button>
                            </div>
                            <div class="text-xs text-gray mt-1">
                                Your photo needs to be at most 100px by 100px.
                            </div>
                            <error v-if="errors.photo">
                                {{ errors.photo[0] }}<br/>
                            </error>
                        </div>
                    </div>
                    <template v-if="isTeamMember">
                        <div class="mt-5">
                            <div class="grid grid-cols-3 gap-5">
                                <div>
                                    <label
                                        for="team"
                                        class="block text-sm leading-5 font-medium text-gray-700"
                                    >
                                        Teams
                                    </label>
                                    <div class="rounded-md shadow-sm">
                                        <hgselect
                                            name="team"
                                            v-model="teams"
                                            :options="teamOptions"
                                            class="mt-1"
                                            multiple
                                        />
                                    </div>
                                    <error v-if="errors.teams">{{
                                        errors.teams[0]
                                    }}</error>
                                </div>
                                <div>
                                    <label
                                        for="default_work_type"
                                        class="block text-sm leading-5 font-medium text-gray-700"
                                    >
                                        Default Work Type
                                    </label>
                                    <div class="rounded-md shadow-sm">
                                        <hgselect
                                            name="default_work_type"
                                            v-model="default_work_type"
                                            :options="typeOptions"
                                            class="mt-1"
                                        />
                                    </div>
                                    <error v-if="errors.default_work_type">{{
                                        errors.default_work_type[0]
                                    }}</error>
                                </div>
                                <div>
                                    <label
                                        for="phone"
                                        class="block text-sm leading-5 font-medium text-gray-700"
                                    >
                                        Phone Number
                                    </label>
                                    <inp v-model="phone" />
                                    <error v-if="errors.phone">{{
                                        errors.phone[0]
                                    }}</error>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 grid grid-cols-3 gap-5">
                            <div>
                                <label
                                    for="accent"
                                    class="block text-sm leading-5 font-medium text-gray-700"
                                >
                                    Accent Color
                                </label>
                                <div class="rounded-md shadow-sm">
                                    <select
                                        name="accent"
                                        v-model="accent"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    >
                                        <option value=''>None</option>
                                        <option value='gray'>Gray</option>
                                        <option value='red'>Red</option>
                                        <option value='orange'>Orange</option>
                                        <option value='yellow'>Yellow</option>
                                        <option value='green'>Green</option>
                                        <option value='teal'>Teal</option>
                                        <option value='blue'>Blue</option>
                                        <option value='indigo'>Indigo</option>
                                        <option value='purple'>Purple</option>
                                        <option value='pink '>Pink </option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label
                                    for="slack_id"
                                    class="block text-sm leading-5 font-medium text-gray-700 mb-1"
                                >
                                    <a
                                        href="https://zapier.com/help/doc/common-problems-slack"
                                        target="_blank"
                                        class="underline"
                                    >Slack User Id</a>
                                </label>
                                <inp v-model="slack_id" />
                            </div>
                            <div>
                                <label
                                    for="accent"
                                    class="block text-sm leading-5 font-medium text-gray-700"
                                >
                                    Timezone
                                </label>
                                <div class="rounded-md shadow-sm">
                                    <select
                                        name="tz"
                                        v-model="tz"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    >
                                        <option value='America/New_York'>Eastern</option>
                                        <option value='America/Chicago'>Central</option>
                                        <option value='America/Denver'>Mountain</option>
                                        <option value='America/Los_Angeles'>Pacific</option>
                                        <option value='Europe/Istanbul'>Turkey</option>
                                        <option value='America/Sao_Paulo'>Brazil</option>
                                        <option value="Asia/Manila">Philippines</option>
                                        <option value="Pacific/Auckland">New Zealand</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="mt-5 grid grid-cols-2 gap-5">
                        <div>
                            <label
                                for="password"
                                class="block text-sm leading-5 font-medium text-gray-700"
                            >
                                Change Password
                            </label>
                            <div class="rounded-md shadow-sm">
                                <input
                                    name="password"
                                    type="password"
                                    v-model="password"
                                    class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                />
                            </div>
                            <error v-if="errors.password">{{
                                errors.password[0]
                            }}</error>
                        </div>
                        <div>
                            <label
                                for="password_confirmation"
                                class="block text-sm leading-5 font-medium text-gray-700"
                            >
                                Confirm Password
                            </label>
                            <div class="rounded-md shadow-sm">
                                <input
                                    name="password_confirmation"
                                    type="password"
                                    v-model="password_confirmation"
                                    class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                />
                            </div>
                            <error v-if="errors.password_confirmation">{{
                                errors.password_confirmation[0]
                            }}</error>
                        </div>
                    </div>
                    <div class="mt-6 sm:mt-5" v-if="!editingProfile">
                        <div class="sm:border-t sm:border-gray-200 sm:pt-5">
                            <div
                                role="group"
                                aria-labelledby="label-permissions"
                            >
                                <div
                                    class="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-baseline"
                                >
                                    <div>
                                        <div
                                            class="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700"
                                            id="label-permissions"
                                        >
                                            Permissions
                                        </div>
                                        <error v-if="hasPermissionsErrors">
                                            Something went wrong with the permissions.
                                        </error>
                                    </div>
                                    <div class="mt-4 sm:mt-0 sm:col-span-3">
                                        <div class="max-w-lg grid grid-cols-2 gap-4">
                                            <div
                                                v-for="perm in groupedPermissions.nonEntries"
                                                :key="'perm-' + perm.key"
                                            >
                                                <div class="relative flex items-start">
                                                    <div class="absolute flex items-center h-5">
                                                        <input
                                                            v-model="permissions"
                                                            :value="perm.key"
                                                            :id="perm.key"
                                                            type="checkbox"
                                                            class="h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                                        />
                                                    </div>
                                                    <div
                                                        class="pl-7 text-sm leading-5"
                                                    >
                                                        <label
                                                            :for="perm.key"
                                                            class="font-medium text-gray-700 cursor-pointer">
                                                            {{ perm.title }}
                                                        </label>
                                                        <p
                                                            class="text-gray-500"
                                                        >
                                                            {{
                                                                perm.description
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="max-w-lg grid grid-cols-2 gap-4 border-t border-gray-200 mt-4 pt-4">
                                            <div
                                                v-for="perm in groupedPermissions.entries"
                                                :key="'perm-' + perm.key"
                                            >
                                                <div class="relative flex items-start">
                                                    <div class="absolute flex items-center h-5">
                                                        <input
                                                            v-model="permissions"
                                                            :value="perm.key"
                                                            :id="perm.key"
                                                            type="checkbox"
                                                            class="h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                                        />
                                                    </div>
                                                    <div
                                                        class="pl-7 text-sm leading-5"
                                                    >
                                                        <label
                                                            :for="perm.key"
                                                            class="font-medium text-gray-700 cursor-pointer">
                                                            {{ perm.title }}
                                                        </label>
                                                        <p
                                                            class="text-gray-500"
                                                        >
                                                            {{
                                                                perm.description
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import error from "../../Common/error.vue"
import btn from "../../Common/btn.vue"
import inp from "../../Common/inp.vue"
import toggle from "../../Common/toggle.vue"
import hgselect from "../../Common/hgselect.vue"
import auth from "../../Common/Mixins/auth"
import avatar from '../../Common/avatar.vue'
import { Head, usePage } from '@inertiajs/vue3'
import axios from "axios"

export default {
    mixins: [auth],
    components: { btn, hgselect, toggle, Layout, Head, error, inp, avatar },
    props: {
        auth: Object,
        user: {
            type: Object,
            default: null
        },
        clients: Object,
        availablePermissions: Array,
        editingProfile: Boolean,
        availableTeams: Array,
        types: Array
    },
    data() {
        return {
            saving: false,
            photo: null,
            id: this.user ? this.user.id : null,
            name: this.user ? this.user.name : "",
            email: this.user ? this.user.email : "",
            phone: this.user ? this.user.phone : "",
            type: this.user ? this.user.type : null,
            client_id: this.user ? this.user.client_id : null,
            slack_id: this.user ? this.user.slack_id : "",
            teams: this.user ? this.user.teams : [],
            default_work_type: this.user ? this.user.default_work_type : null,
            permissions: (this.user ? this.user.permissions : []) || [],
            accent: (this.user ? this.user.accent : '') || '',
            tz: (this.user ? this.user.tz : 'America/Chicago') || 'America/Chicago',
            password: "",
            password_confirmation: "",
            is_active: this.user ? this.user.is_active : true,
            trello_connected: null,
            errors: usePage().props.errors || {}
        }
    },
    computed: {
        isTeamMember() {
            if (this.user) {
                return this.user.type == 'team';
            }
            return this.auth.user.type == 'team';
        },
        hasPermissionsErrors() {
            const keys = Object.keys(this.errors)
            return keys.some((key) => key.match(/^permissions\./))
        },
        teamOptions () {
            var options = {}
            this.availableTeams.forEach((team) => {
                options[team.id] = team.name
            })
            return options
        },
        userTypeOptions () {
            var options = {
                'team': 'Team Member',
                'anonymous': 'Anonymous User',
                'client': 'Client User',
            }
            return options
        },
        typeOptions () {
            var options = {}
            this.types.forEach((type) => {
                options[type.slug] = type.name
            })
            return options
        },
        groupedPermissions () {
            const entryPerms = []
            const nonEntryPerms = []
            this.availablePermissions.forEach((perm) => {
                if (perm.key.match(/^(view|edit):/) && perm.key != "view:reports") {
                    entryPerms.push(perm)
                } else {
                    nonEntryPerms.push(perm)
                }
            })

            return {
                entries: entryPerms,
                nonEntries: nonEntryPerms,
            }
        }
    },
    mounted() {
        if (this.editingProfile) {
            this.checkTrello()
        }
    },
    methods: {
        connectTrello() {
            window.location.href = this.$route('trello.start')
        },
        disconnectTrello() {
            axios.post(this.$route('trello.disconnect'))
                .then(() => {
                    this.trello_connected = false
                })
        },
        async checkTrello() {
            const { data } = await axios.get(this.$route('trello.check'))
            this.trello_connected = data.result
        },
        photoChanged (event) {
            this.photo = event.target.files[0]
        },
        async save() {
            this.saving = true
            const data = new FormData()
            data.append("name", this.name)
            data.append("type", this.type)
            data.append("client_id", this.client_id)
            data.append("email", this.email)
            data.append("phone", this.phone)
            data.append("slack_id", this.slack_id)
            data.append("default_work_type", this.default_work_type)
            data.append("accent", this.accent)
            data.append("tz", this.tz)
            data.append("is_active", this.is_active ? 1 : 0)
            if (this.photo) {
                data.append("photo", this.photo)
            }
            this.permissions.forEach((p) => data.append("permissions[]", p))
            this.teams.forEach((p) => data.append("teams[]", p))

            if (this.password) {
                data.append("password", this.password)
                data.append("password_confirmation", this.password_confirmation)
            }

            try {
                if (this.id) {
                    data.append("_method", "PUT")
                    await axios.post(
                        this.$route("users.update", this.id),
                        data
                    )
                } else {
                    await axios.post(this.$route("users.store"), data)
                }
            } catch (err) {
                this.errors = err.response.data.errors || null
            }
            this.saving = false
            this.$emit('saved')
        }
    }
}
</script>

<style lang="postcss" scoped></style>
